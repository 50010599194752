import { refreshVppnToken } from 'api/axios/vppn';
import { isDevEnv } from 'library/constants';
import { decode, isExpired } from 'library/utils/helpers/JwtHelper';

import { AuthenticationProvider } from './Provider';

export class VppnProvider extends AuthenticationProvider {
	private token: string;
	private tokenRefreshingPromise: Promise<string> | null = null;

	// by default, we should receive valid token from the frontend server, so we can use it right away for calls
	constructor(token: string) {
		super();
		this.token = token;
	}

	public async getTokenAsync(): Promise<string> {
		// check if token isExpired and refresh it immediately before returning
		if (isDevEnv) {
			console.log(`getTokenAsync - isExpired:${isExpired(this.token)}`);
		}
		if (!this.isTokenValid()) {
			const token = await this.refreshToken();

			return token;
		}

		return this.token;
	}

	public isAuthenticated(): Promise<boolean> {
		if (this.tokenRefreshingPromise != null) {
			return this.tokenRefreshingPromise
				.then((value) => {
					return value != null;
				})
				.catch(() => false);
		}

		return Promise.resolve(this.isTokenValid());
	}

	public onAuthenticated(
		fn: (isAuth: boolean, message?: string) => void
	): void {
		// when onAuthenticated is called first time, we can invoke the callback,
		// because the client should be initiated with valid token at the start
		super.onAuthenticated(fn);
		this.invokeOnAuthenticatedCallback();
	}

	public static validateToken(token: string) {
		// token is only valid if it is not expired, will cause rerender loop
		return token != null && !isExpired(token);
	}

	private isTokenValid() {
		return VppnProvider.validateToken(this.token);
	}

	private async refreshToken(): Promise<string> {
		if (this.tokenRefreshingPromise != null) {
			return this.tokenRefreshingPromise;
		}

		this.tokenRefreshingPromise = this.refreshTokenInternal().finally(
			() => {
				this.tokenRefreshingPromise = null;
			}
		);

		return this.tokenRefreshingPromise;
	}

	private async refreshTokenInternal() {
		try {
			const { sub: userId = '' } = decode(this.token);

			const token = await refreshVppnToken(userId);

			if (token != null) {
				this.token = token;
			}
		} catch (error) {
			if (isDevEnv) {
				console.error(`refreshTokenInternal - ${error as string}`);
			}
		}
		this.invokeOnAuthenticatedCallback();
		return this.token;
	}

	private invokeOnAuthenticatedCallback(): void {
		this.onAuthenticatedCallback(
			this.isTokenValid(),
			this.isTokenValid() ? 'JWT is correct' : 'Invalid JWT'
		);
	}
}
