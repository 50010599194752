import { TextField, styled } from '@mui/material';

export const SearchProductTextField = styled(TextField)(({ theme }) => ({
	// fix for input overflow bug, may be fixed in later MUI version
	'& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
		left: theme.spacing(4),
		right: theme.spacing(4),
	},
	'& .MuiInputLabel-shrink': {
		display: 'none',
	},
	[theme.breakpoints.up('sm')]: {
		'& label.MuiInputLabel-shrink+.MuiInputBase-root': {
			marginTop: theme.spacing(2),
		},
		'& label+.MuiInputBase-root': {
			marginTop: theme.spacing(2),
		},
		'& .MuiInputLabel-root': {
			fontSize: '1.5rem',
		},
		'& .MuiInputBase-input': {
			fontSize: '1.5rem',
		},
	},
}));
