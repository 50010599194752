import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, Typography, useTheme } from '@mui/material';
import { noop } from 'lodash';
import { PropsWithChildren, ReactNode, useCallback } from 'react';

import { Sxable } from 'library/types';

import { Accordion, AccordionSummary } from './Accordion';
import { useAccordion } from './useAccordion';

export const AccordionItem = ({
	accordionName,
	header,
	HeaderComponent,
	children,
	onChange = noop,
	sx = {},
}: PropsWithChildren<{
	accordionName: string;
	header?: string;
	HeaderComponent?: ReactNode;
	onChange?: (name: string) => void;
}> &
	Sxable) => {
	const { checkIfExpanded, onChange: onAccordionChange } = useAccordion();
	const {
		palette: {
			gray: { main },
		},
	} = useTheme();

	const handleOnChange = useCallback(() => {
		onAccordionChange(accordionName);
		onChange(accordionName);
	}, [accordionName, onAccordionChange, onChange]);

	return (
		<Accordion
			id={accordionName}
			sx={{
				'&.Mui-expanded': { background: 'white' },
				...sx,
			}}
			expanded={checkIfExpanded(accordionName)}
			onChange={handleOnChange}>
			<AccordionSummary
				sx={{ '&:hover': { background: main } }}
				expandIcon={<ExpandMore />}>
				{HeaderComponent
					? HeaderComponent
					: header && <Typography>{header}</Typography>}
			</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
};
