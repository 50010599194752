import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UnitRegistrationModelGR } from 'api';
import { DimensionsType } from 'api/responses/models/Unit/UnitMeasurement';
import { SubmitErrorResponse } from 'library/exceptions/ValidationErrors';
import {
	ApiStatus,
	UnitConfigurationType,
	UnitDataFormTypes,
	UnitMetadata,
} from 'modules/Shared/State/unit/Types';

import { findPosition } from './Functions';
import {
	resetGuidedRegistrationState,
	searchIndividual,
	submitUnitRegistration,
} from './Thunks';
import { GuidedRegistrationState, InstallationMatchPosition } from './Types';

export const DEFAULT_DIMENSIONS_TYPE: DimensionsType = 'Estimated';

export const initialState: GuidedRegistrationState = {
	activeStep: 0,
	isBlueprint: false,
	status: ApiStatus.Idle,
	type: UnitConfigurationType.CREATION,
	unitData: {
		hullIdStandard: null,
		dimensionsType: DEFAULT_DIMENSIONS_TYPE,
		length: '',
		lengthUnit: '',
		width: '',
		widthUnit: '',
		displacement: '',
		displacementUnit: '',
		lineOfBusiness: '',
		unitPurpose: '',
	},
	unitMetadata: {
		lineOfBusiness: undefined,
		lineOfBusinessDescription: undefined,
		unitPurpose: undefined,
		unitPurposeDescription: undefined,
		mainSegment: 'M', // TODO: remove when GR wizard is done
	},
};

export const guidedRegistrationSlice = createSlice({
	name: 'guidedRegistration',
	initialState,
	reducers: {
		nextStep: (state) => {
			state.activeStep += 1;
		},
		previousStep: (state) => {
			if (state.activeStep === 0) {
				return;
			}

			state.activeStep -= 1;
		},
		reset: () => initialState,
		setIsAdded: (state) => {
			if (!!state.model && state.model.isQueued) {
				state.model.isAdded = true;
				state.model.isQueued = false;
			}
		},
		setIsBlueprint: (state) => {
			state.isBlueprint = true;
		},
		setIsQueued: (state) => {
			if (!!state.model && !state.model.isAdded) {
				state.model.isQueued = true;
				state.model.isAdded = false;
			}
		},
		setModel: (
			state,
			{ payload }: PayloadAction<UnitRegistrationModelGR>
		) => {
			state.model = { ...payload, isAdded: true };
		},
		setInstallationMatchToModel: (
			state,
			{
				payload: { idTemporary, ...matchRest },
			}: PayloadAction<InstallationMatchPosition>
		) => {
			const position = findPosition(idTemporary, state.model?.tree);
			if (position == null || !position.isInstallation) {
				// do not update if position is not an installation
				return;
			}

			if (
				position.matchedInstallation?.detailedSegmentKey !==
				matchRest.detailedSegmentKey
			) {
				matchRest.installationPurposeKey = null;
				matchRest.operatingProfileKey = null;
			} else if (
				position.matchedInstallation?.installationPurposeKey !==
				matchRest.installationPurposeKey
			) {
				matchRest.operatingProfileKey = null;
			}

			position.matchedInstallation = matchRest;
		},
		setUnitMetadata: (
			state,
			{
				payload: {
					lineOfBusiness,
					lineOfBusinessDescription,
					mainSegment,
					unitPurpose,
					unitPurposeDescription,
				},
			}: PayloadAction<Partial<UnitMetadata> & { isReset?: boolean }>
		) => {
			let newMetadata: UnitMetadata | undefined;

			if (mainSegment && mainSegment !== state.unitMetadata.mainSegment) {
				state.unitMetadata.mainSegment = mainSegment;
				newMetadata = { mainSegment };
			}

			if (lineOfBusiness && lineOfBusinessDescription) {
				newMetadata = {
					mainSegment: state.unitMetadata.mainSegment,
					lineOfBusiness,
					lineOfBusinessDescription: lineOfBusinessDescription,
				};
			}

			if (unitPurpose && unitPurposeDescription) {
				newMetadata = {
					...state.unitMetadata,
					...newMetadata,
					unitPurpose,
					unitPurposeDescription,
				};
			}

			if (newMetadata) {
				state.unitMetadata = newMetadata;
			}
		},
		updateFormData: (
			state,
			{ payload }: PayloadAction<UnitDataFormTypes>
		) => {
			state.unitData = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(searchIndividual.pending, (state) => {
				state.status = ApiStatus.Pending;
			})
			.addCase(
				searchIndividual.fulfilled,
				(state, { payload: { gr } }) => {
					state.model = gr;
					state.status = ApiStatus.Idle;
				}
			)
			.addCase(submitUnitRegistration.fulfilled, (state, action) => {
				if (action.payload) {
					state.createdUnitId = action.payload;
				}
			})
			.addCase(submitUnitRegistration.rejected, (state, { payload }) => {
				state.submitErrors = payload as SubmitErrorResponse;
			})
			.addCase(resetGuidedRegistrationState.fulfilled, (state) => ({
				...initialState,
				createdUnitId: state.createdUnitId, // needed for the last step
			}));
	},
});

export const {
	actions: {
		nextStep,
		previousStep,
		reset,
		setIsAdded,
		setIsBlueprint,
		setIsQueued,
		setModel,
		setUnitMetadata,
		setInstallationMatchToModel,
		updateFormData,
	},
} = guidedRegistrationSlice;

export default guidedRegistrationSlice.reducer;
