import { PositionDto, RootPositionDto } from 'api';

type PositionFunctionType = (
	position: RootPositionDto | PositionDto | null | undefined
) => PositionDto[];

export const getBlueprintProducts: PositionFunctionType = (position) =>
	position ? getAllDataTypes(position, 'blueprintProductIndividual') : [];

export const getBlueprintPartForSales: PositionFunctionType = (position) =>
	position ? getAllDataTypes(position, 'blueprintPartForSale') : [];

export const getMatchedInstallations: PositionFunctionType = (position) =>
	position ? getAllDataTypes(position, 'matchedInstallation') : [];

export const getBlueprintInstallations: PositionFunctionType = (position) =>
	position ? getAllDataTypes(position, 'isInstallation', true) : [];

export const getBlueprintDrivelines: PositionFunctionType = (position) =>
	position ? getAllDataTypes(position, 'isPhysicalDriveline', true) : [];

export const findPosition = (
	position: PositionDto | RootPositionDto,
	idTemporary: string
) => {
	for (const positionChild of getPositionIterator(
		position as unknown as PositionDto
	)) {
		if (positionChild.idTemporary === idTemporary) {
			return positionChild;
		}
	}

	return null;
};

export function* getPositionIterator(
	position: PositionDto
): Generator<PositionDto> {
	yield position;

	if (!position.children) {
		return;
	}

	for (const child of position.children) {
		yield* getPositionIterator(child);
	}
}

/**
 * Function can be used to search for every position with field, that can be passed as parameter.
 * Function will bail out early, if the position with dataFieldName was found.
 * Should only be used for searching entities, that cannot have same dataFieldName as child
 * @returns {PositionDto[]}
 */
export const getAllDataTypes = (
	position: RootPositionDto | PositionDto,
	dataFieldName: keyof PositionDto,
	fieldMustBeTruthy?: boolean
): PositionDto[] => {
	const drivelines: PositionDto[] = [];

	const getInternal = (position: PositionDto) => {
		const doesFieldExists = position[dataFieldName] != null;
		const doesFieldHasTrueValue = !!position[dataFieldName];

		if (doesFieldExists && (!fieldMustBeTruthy || doesFieldHasTrueValue)) {
			drivelines.push(position);

			// bail out early, as  cannot contain another driveline
			return;
		}

		if (position.children == null) {
			return;
		}

		for (const positionChild of position.children) {
			getInternal(positionChild);
		}
	};

	getInternal(position as unknown as PositionDto);

	return drivelines;
};
