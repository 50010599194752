import { JwtPayload, jwtDecode } from 'jwt-decode';

const emptyJwtPayload: JwtPayload = {
	iss: '',
	sub: '',
	aud: '',
	exp: 0,
	nbf: 0,
	iat: 0,
	jti: '',
};

export const decode = (
	token: string,
	defaultValue: Partial<JwtPayload> = emptyJwtPayload
): JwtPayload | Partial<JwtPayload> => {
	try {
		return jwtDecode(token);
	} catch (_error) {
		return defaultValue;
	}
};

export const getExpDateInMs = (token: string): number => {
	const { exp }: JwtPayload = decode(token, { exp: 0 });

	return exp !== undefined ? exp * 1000 : 0;
};

export const getExpDateDiffInMs = (token: string, thresholdInMs = 60000) =>
	getExpDateInMs(token) - thresholdInMs - new Date().getTime();

export const isExpired = (token: string): boolean =>
	getExpDateInMs(token) < new Date().getTime();
