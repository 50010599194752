import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLoaderData, useLocation } from 'react-router-dom';

import { redirectToErrorPage } from 'api/axios/responses/interceptors/AuthErrorResponseInterceptor';
import { Spinner } from 'library/components/controls';
import { setHistory } from 'library/redux/application';
import { useAppDispatch } from 'library/redux/hooks';
import { SnackbarWrapper } from 'library/snackbar';
import {
	AuthProviderStorage,
	AuthenticationProvider,
} from 'main/auth/provider';
import { ErrorFallback } from 'main/errors/ErrorFallback';
import Layout from 'main/layout/Layout';
import { errors_AuthenticationUrl } from 'main/routes/urls/RouteUrls';

const handleError = (
	message?: string,
	redirectPage = errors_AuthenticationUrl
) => {
	if (message) {
		console.error(message);
	}
	redirectToErrorPage(redirectPage);
};

const RootAuthentication = () => {
	const location = useLocation();
	const authProvider = useLoaderData() as AuthenticationProvider;

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setHistory(location));
	}, [dispatch, location]);

	const onAuthenticated = useCallback((isAuth: boolean, message?: string) => {
		if (!isAuth) {
			handleError(message);
		}
	}, []);

	const AuthProvider = useMemo(() => {
		if (authProvider == null) {
			return AuthProviderStorage.getDefaultProviderComponent();
		}

		authProvider.onAuthenticated(onAuthenticated);
		return authProvider.getComponent();
	}, [authProvider, onAuthenticated]);

	// ErrorBoundary and Layout needs token for API calls
	return (
		<AuthProvider>
			<Layout>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<SnackbarWrapper>
						<Suspense fallback={<Spinner />}>
							<Outlet />
						</Suspense>
					</SnackbarWrapper>
				</ErrorBoundary>
			</Layout>
		</AuthProvider>
	);
};

export default RootAuthentication;
