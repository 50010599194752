import { useAccount, useMsal } from '@azure/msal-react';
import { PropsWithChildren, useEffect } from 'react';

import { setADUser } from 'library/redux/application';

export const MsalAccountSetting = ({
	children,
	onMsalChanged,
}: PropsWithChildren<{
	onMsalChanged: (isInitialized: boolean) => void;
}>) => {
	const { instance, accounts, inProgress } = useMsal();
	const account = useAccount(accounts[0] || {});

	useEffect(() => {
		if (account && inProgress === 'none') {
			instance.setActiveAccount(account);
			setADUser(account);
			onMsalChanged(!!account);
		}
	}, [account, instance, onMsalChanged, inProgress]);

	return children;
};
