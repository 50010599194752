import { Container, SxProps } from '@mui/material';
import { PropsWithChildren, useState } from 'react';

import 'assets/styles/components.scss';
import 'assets/styles/main.scss';
import { useLayoutStyle } from 'library/hooks/useLayoutStyle';

import Body from './Body';
import Global from './Global';
import Footer from './footer/Footer';
import Header from './header/Header';
import IMenu from './header/nav/models/IMenu';
import ISubMenu from './header/nav/models/ISubMenu';

const footerSxProps: SxProps = {
	display: {
		xs: 'none',
		md: 'block',
	},
};

const Layout = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	const { bodyContainerPaddingY, minHeight } = useLayoutStyle();
	const [isTabletMenuOpen, setIsTabletMenuOpen] = useState<boolean>(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
	const [selectedMenu, setSelectedMenu] = useState<IMenu | ISubMenu | null>(
		null
	);

	return (
		<Container
			id="container"
			maxWidth="lg"
			sx={{
				overflow: 'hidden',
			}}>
			<Global />
			<Header
				isTabletMenuOpen={isTabletMenuOpen}
				setIsTabletMenuOpen={setIsTabletMenuOpen}
				isMobileMenuOpen={isMobileMenuOpen}
				setIsMobileMenuOpen={setIsMobileMenuOpen}
				selectedMenu={selectedMenu}
				setSelectedMenu={setSelectedMenu}
			/>
			<Body
				sx={{
					paddingY: bodyContainerPaddingY,
					display: {
						xs: isMobileMenuOpen ? 'none' : 'flex',
						sm: 'flex',
					},
					flexDirection: 'column',
					minHeight,
					height: {
						sm: isTabletMenuOpen ? minHeight.xs : 'unset',
						md: 'unset',
					},
					// overflowX setting removed so that it allows absolute positioned items to display some effects outside the body window.
				}}>
				{children}
			</Body>
			<Footer sx={footerSxProps} />
		</Container>
	);
};

export default Layout;
