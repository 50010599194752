import { Draft, PayloadAction, Update, createSlice } from '@reduxjs/toolkit';
import { first, remove } from 'lodash';

import {
	DrivelineDto,
	ProductIndividualDto,
} from 'api/responses/models/UnitCreation/ProductIndividualSearchResultDto';
import { ProductIndividualSearchResult } from 'domain/productIndividual';
import {
	InstallationDrivelineId,
	InstallationId,
	ProductId,
} from 'domain/unit';
import { generateUUID } from 'library/utils/generateUuid';
import { searchIndividual } from 'modules/GuidedRegistration/State/Thunks';

import {
	installationList,
	installationListSelectors,
	searchItemsList,
} from './Entity';
import { shouldDisplayOperatingProfile } from './Functions';
import { getProductIndividuals } from './Thunks';
import {
	ApiStatus,
	CommonUnitState,
	Installation,
	InstallationMetadata,
	UnitConfigurationType,
	UnitMetadata,
	UnitState,
} from './Types';

export const commonInitialState: CommonUnitState = {
	status: ApiStatus.Idle,
};

export const initialState: UnitState = {
	activeStep: 0,
	installationList: installationList.getInitialState(),
	searchItemsList: searchItemsList.getInitialState(),
	searchItemWithConfigurationDetected: null,
	shouldLoadConfigurationForItem: null,
	unitMetadata: {
		mainSegment: 'M',
	},
	...commonInitialState,
	type: UnitConfigurationType.CREATION,
};

export const unitSlice = createSlice({
	name: 'unit',
	initialState,
	reducers: {
		addNewInstallation: (state, { payload }: PayloadAction<string>) => {
			const searchResult = state.searchItemsList.entities[payload];

			if (!searchResult) {
				return;
			}

			// merge installation if the same driveline already exists
			let isMerged = false;
			if (searchResult.infoSource === 'PC') {
				let sameDriveline: DrivelineDto | undefined;

				const sameInstallation = Object.values(
					state.installationList.entities
				).find((installation) =>
					installation?.drivelines.find((driveline) => {
						sameDriveline = driveline;
						return (
							driveline.chassisId ===
							searchResult.partialUnitStructure.installations[0]
								.drivelines[0].chassisId
						);
					})
				);

				if (sameInstallation && sameDriveline) {
					const productsIndividuals =
						searchResult.partialUnitStructure.installations.flatMap(
							(installation) =>
								installation.drivelines.flatMap(
									(driveline) => driveline.productIndividuals
								)
						);

					sameDriveline.productIndividuals.push(
						productsIndividuals[0]
					);

					isMerged = true;
				}
			}

			if (!isMerged) {
				const installations =
					searchResult.partialUnitStructure.installations.map(
						(installation) => ({
							...installation,
							generatedUuid: generateUUID(),
						})
					);

				const mergedInstallationsByKeys = [];
				for (const installation of installations) {
					if (!mergeInstallationIfSameKeys(state, installation)) {
						mergedInstallationsByKeys.push(installation);
					}
				}

				// done one separate line, to not mess up order of installation coming from API
				installationList.setAll(state.installationList, [
					...mergedInstallationsByKeys,
					...installationListSelectors.selectAll(state),
				]);
			}
			clearStateAfterNewProductAdded(state, searchResult.generatedUuid);
		},
		addNewProduct: (
			state,
			action: PayloadAction<
				InstallationDrivelineId & {
					product: ProductIndividualDto;
					searchResultId: string;
				}
			>
		) => {
			addProductInternal(state, action);
			clearStateAfterNewProductAdded(
				state,
				action.payload.searchResultId
			);
		},
		addProduct: addProductInternal,
		addProductToSearchItemList: (
			state,
			{ payload }: PayloadAction<{ shouldLoadConfiguration: boolean }>
		) => {
			state.shouldLoadConfigurationForItem =
				payload.shouldLoadConfiguration;

			addProductToSearchItemListInternal(
				state,
				state.searchItemWithConfigurationDetected
			);
		},
		changeDrivelinePosition: (
			state,
			{
				payload,
			}: PayloadAction<
				InstallationDrivelineId & {
					drivelinePosition: number;
				}
			>
		) => {
			const driveline = getDrivelineById(state, payload);

			if (!driveline) {
				return;
			}

			driveline.drivelinePosition = payload.drivelinePosition;
		},
		detachDrivelineFromInstallation: (
			state,
			{
				payload,
			}: PayloadAction<
				InstallationId & {
					driveline: DrivelineDto;
				}
			>
		) => {
			const oldInstallation = installationListSelectors.selectById(
				state,
				payload.installationId
			);

			if (!oldInstallation) {
				return;
			}

			const drivelineListAfterDisconnecting =
				oldInstallation.drivelines.filter(
					(driveline) =>
						driveline.chassisId !== payload.driveline.chassisId
				);

			const updateObj: Update<Installation, string> = {
				id: payload.installationId,
				changes: {
					drivelines: [...drivelineListAfterDisconnecting],
				},
			};

			installationList.updateOne(state.installationList, updateObj);

			// create new installation for driveline and attach it

			const instalationToBeAdded: Installation = {
				generatedUuid: generateUUID(),
				configurationId: null,
				drivelines: [payload.driveline],
				installationPurposeKey: null,
				detailedSegmentKey: oldInstallation.detailedSegmentKey,
				operatingProfileKey: null,
				sortPosition: 99,
			};

			installationList.addOne(
				state.installationList,
				instalationToBeAdded
			);
		},
		nextStep: (state) => {
			state.activeStep += 1;
		},
		previousStep: (state) => {
			if (state.activeStep === 0) {
				return;
			}

			state.activeStep -= 1;
		},
		removeFromWaitingList: (state, action: PayloadAction<string>) => {
			searchItemsList.removeOne(state.searchItemsList, action.payload);
		},
		setConfigurationType: (
			state,
			{ payload }: PayloadAction<UnitConfigurationType>
		) => {
			state.type = payload;
		},
		setInstallation: (
			{ installationList: stateInstallationList },
			{ payload }: PayloadAction<Installation[]>
		) => {
			installationList.setAll(stateInstallationList, payload);
		},
		setInstallationMetadata: (
			state,
			{
				payload: { installationId, ...metadata },
			}: PayloadAction<InstallationId & Partial<InstallationMetadata>>
		) => {
			const installation = installationListSelectors.selectById(
				state,
				installationId
			);

			if (!installation) {
				return;
			}

			// if new instalation purpose is not passed, take existing one
			const newOperatingProfileKey = shouldDisplayOperatingProfile(
				metadata.installationPurposeKey ||
					installation.installationPurposeKey
			)
				? installation.operatingProfileKey
				: null;

			if (metadata.detailedSegmentKey) {
				metadata = {
					installationPurposeKey: installation.installationPurposeKey,
					operatingProfileKey: newOperatingProfileKey,
					...metadata,
				};
			} else if (metadata.installationPurposeKey) {
				metadata = {
					operatingProfileKey: newOperatingProfileKey,
					...metadata,
				};
			}

			if (
				mergeInstallationIfSameKeys(state, {
					...installation,
					...metadata,
				})
			) {
				installationList.removeOne(
					state.installationList,
					installationId
				);
			} else {
				installationList.updateOne(state.installationList, {
					id: installationId,
					changes: metadata,
				});
			}
		},
		setShouldLoadConfigurationForItem: (
			state,
			{ payload }: PayloadAction<boolean>
		) => {
			state.shouldLoadConfigurationForItem = payload;
		},
		setUnitMetadata: (
			state,
			{
				payload: {
					lineOfBusiness,
					lineOfBusinessDescription,
					mainSegment,
					unitPurpose,
					unitPurposeDescription,
					isReset = true,
				},
			}: PayloadAction<Partial<UnitMetadata> & { isReset?: boolean }>
		) => {
			let newMetadata: UnitMetadata | undefined;

			if (mainSegment && mainSegment !== state.unitMetadata.mainSegment) {
				state.unitMetadata.mainSegment = mainSegment;
				newMetadata = { mainSegment };

				if (isReset) {
					const changes: Partial<InstallationMetadata> = {
						detailedSegmentKey: null,
						installationPurposeKey: null,
						operatingProfileKey: null,
						sortPosition: 99,
					};

					// clear detailed segments choice
					installationList.updateMany(
						state.installationList,
						installationListSelectors
							.selectAll(state)
							.map((installation) => ({
								id: installation.generatedUuid,
								changes,
							}))
					);
				}
			}

			if (lineOfBusiness && lineOfBusinessDescription) {
				newMetadata = {
					mainSegment: state.unitMetadata.mainSegment,
					lineOfBusiness,
					lineOfBusinessDescription: lineOfBusinessDescription,
				};
			}

			if (unitPurpose && unitPurposeDescription) {
				newMetadata = {
					...state.unitMetadata,
					...newMetadata,
					unitPurpose,
					unitPurposeDescription,
				};
			}

			if (newMetadata) {
				state.unitMetadata = newMetadata;
			}
		},
		removeDriveline: (
			state,
			{ payload }: PayloadAction<InstallationDrivelineId>
		) => {
			removeDrivelineInternal(state, payload);
		},
		removeProduct: (
			state,
			{
				payload,
			}: PayloadAction<
				InstallationDrivelineId & Pick<ProductId, 'productId'>
			>
		) => {
			const driveline = getDrivelineById(state, payload);

			if (!driveline) {
				return;
			}

			remove(
				driveline.productIndividuals,
				({ generatedUuid }) => generatedUuid === payload.productId
			);

			// Check if it is last child of drivelineList
			if (!first(driveline.productIndividuals)) {
				removeDrivelineInternal(state, payload);
			}
		},
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// TODO: move to GR Thunk after fully switch to GR #874908
			.addCase(searchIndividual.pending, (state) => {
				state.status = ApiStatus.Pending;
			})
			// TODO: move to GR Thunk after fully switch to GR #874908
			.addCase(searchIndividual.fulfilled, (state, { payload }) => {
				state.status = ApiStatus.Idle;
				if (payload) {
					const { manual } = payload;
					switch (manual.infoSource) {
						case 'PC':
							searchItemsList.addOne(
								state.searchItemsList,
								manual
							);
							break;
						case 'Blueprint':
							break; // it's handled by GR slice
						default: // EVC | EVC2.0
							if (state.shouldLoadConfigurationForItem !== null) {
								addProductToSearchItemListInternal(
									state,
									manual
								);
							} else {
								state.searchItemWithConfigurationDetected =
									manual;
							}
					}
				}
			})
			// TODO: remove after fully switch to GR #874908 - to support manual registration when GR is turned off by the environment variable - VITE_IS_GUIDED_REGISTRATION
			.addCase(getProductIndividuals.pending, (state) => {
				state.status = ApiStatus.Pending;
			})
			// TODO: remove after fully switch to GR #874908 - to support manual registration when GR is turned off by the environment variable - VITE_IS_GUIDED_REGISTRATION
			.addCase(getProductIndividuals.fulfilled, (state, action) => {
				state.status = ApiStatus.Idle;
				if (action.payload) {
					if (action.payload.infoSource !== 'PC') {
						if (state.shouldLoadConfigurationForItem !== null) {
							addProductToSearchItemListInternal(
								state,
								action.payload
							);
						} else {
							state.searchItemWithConfigurationDetected =
								action.payload;
						}
					} else {
						searchItemsList.addOne(
							state.searchItemsList,
							action.payload
						);
					}
				}
			});
	},
});

export const {
	actions: {
		addNewInstallation,
		addNewProduct,
		addProductToSearchItemList,
		addProduct,
		changeDrivelinePosition,
		detachDrivelineFromInstallation,
		nextStep,
		previousStep,
		removeDriveline,
		removeFromWaitingList,
		removeProduct,
		reset,
		setConfigurationType,
		setInstallation,
		setInstallationMetadata,
		setShouldLoadConfigurationForItem,
		setUnitMetadata,
	},
} = unitSlice;

export default unitSlice.reducer;

function addProductInternal(
	state: Draft<UnitState>,
	{
		payload,
	}: PayloadAction<
		InstallationDrivelineId & {
			product: ProductIndividualDto;
		}
	>
) {
	const driveline = getDrivelineById(state, payload);

	if (!driveline) {
		return;
	}

	driveline.productIndividuals.push({
		...payload.product,
		isProductWithoutDriveline: true,
	});
}

function addProductToSearchItemListInternal(
	state: Draft<UnitState>,
	newProduct: ProductIndividualSearchResult | null
) {
	if (!newProduct) {
		return;
	}

	if (state.shouldLoadConfigurationForItem) {
		searchItemsList.setOne(state.searchItemsList, newProduct);
	} else {
		let productIndividual: ProductIndividualDto | undefined;
		let driveline: DrivelineDto | undefined;

		const singleInstallation =
			newProduct.partialUnitStructure.installations.find((x) => {
				driveline = x.drivelines.find((y) => {
					productIndividual = y.productIndividuals.find(
						(z) =>
							z.serialNumber.toUpperCase() ===
							newProduct.requestedSerialNumber.toUpperCase()
					);
					return productIndividual;
				});
				return driveline;
			});

		if (singleInstallation && driveline && productIndividual) {
			const newInstallation: ProductIndividualSearchResult = {
				...newProduct,
				infoSource: 'PC',
				partialUnitStructure: {
					installations: [
						{
							...singleInstallation,
							drivelines: [
								{
									...driveline,
									productIndividuals: [productIndividual],
								},
							],
						},
					],
				},
			};

			searchItemsList.setOne(state.searchItemsList, newInstallation);
		}
	}
}

function clearStateAfterNewProductAdded(state: Draft<UnitState>, uuid: string) {
	searchItemsList.removeOne(state.searchItemsList, uuid);
}

function getDrivelineById(
	state: Draft<UnitState>,
	payload: InstallationDrivelineId
): Draft<DrivelineDto> | undefined {
	const installation =
		state.installationList.entities[payload.installationId];

	if (!installation) {
		return undefined;
	}

	return installation.drivelines.find(
		(driveline) => driveline.chassisId === payload.drivelineId
	);
}

function mergeInstallationIfSameKeys(
	state: Draft<UnitState>,
	installation: Installation
): boolean {
	const {
		detailedSegmentKey,
		drivelines,
		generatedUuid,
		installationPurposeKey,
		operatingProfileKey,
	} = installation;
	// check if any installation already exists with the same options selected
	// make a function out of it
	const isAllOptionsChosen =
		detailedSegmentKey &&
		installationPurposeKey &&
		(shouldDisplayOperatingProfile(installationPurposeKey)
			? operatingProfileKey
			: true);

	if (!isAllOptionsChosen) {
		return false;
	}

	const sameInstallation = installationListSelectors
		.selectAll(state)
		.find(
			(other) =>
				other.generatedUuid !== generatedUuid &&
				other.detailedSegmentKey === detailedSegmentKey &&
				other.installationPurposeKey === installationPurposeKey &&
				(shouldDisplayOperatingProfile(installationPurposeKey)
					? (other.operatingProfileKey || null) ===
						(operatingProfileKey || null)
					: true)
		);

	if (!sameInstallation) {
		return false;
	}

	const updateObj: Update<Installation, string> = {
		id: sameInstallation.generatedUuid,
		changes: {
			drivelines: [...sameInstallation.drivelines, ...drivelines],
		},
	};

	installationList.updateOne(state.installationList, updateObj);

	return true;
}

function removeDrivelineInternal(
	{ installationList: stateInstallationList }: Draft<UnitState>,
	{ drivelineId, installationId }: InstallationDrivelineId
) {
	const installation = stateInstallationList.entities[installationId];

	if (!installation) {
		return;
	}

	remove(
		installation.drivelines,
		(driveline) => driveline.chassisId === drivelineId
	);

	if (!first(installation.drivelines)) {
		installationList.removeOne(stateInstallationList, installationId);
	}
}
