import { BookmarkAdd, BookmarkBorder } from '@mui/icons-material';
import {
	GridColDef, GridDensity
} from '@mui/x-data-grid-pro';
import {
	Box,
	Stack,
	Typography,
} from '@mui/material';
import { upperFirst } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
	CoverageCodeDto,
} from 'api';
import { getLocalizedDateFormat } from 'library/formatters';
import { Sxable } from 'library/types';
import { LocalizedDataGrid } from 'library/components/controls/datagrid/LocalizedDataGrid';


interface CoverageDataGridProps extends Sxable {
	coverages: CoverageCodeDto[];
	dataGridHeight?: string;
	density?: GridDensity; 
};

export const CoverageDataGrid = ({ coverages, dataGridHeight = 'auto', density, sx }: CoverageDataGridProps) => {
	const { t } = useTranslation(['common', 'maintenancePlanner', 'warranty']);
	
	const columns = useMemo(
		(): GridColDef<CoverageCodeDto>[] => [
			{
				field: 'isExtended',
				headerName: 'Type',
				flex: 2,
				minWidth: 120,
				renderCell: ({ row: { isExtended } }) => (
					<Stack
                        direction="row"
                        sx={{
                            alignItems: "center",
                            gap: 1
                        }}>
						{isExtended ? <BookmarkAdd /> : <BookmarkBorder />}
						<Typography variant="body2">
							{isExtended
								? t('warranty:extended-type')
								: t('warranty:standard-type')}
						</Typography>
					</Stack>
				),
				sortable: false,display: 'flex'
			},
			{
				field: 'code',
				headerName: t('warranty:code'),
				flex: 1,
				minWidth: 80,
				display: 'flex',
				sortable: false,
			},
			{
				field: 'description',
				headerName: t('common:description'),
				flex: 8,
				minWidth: 300,
				sortable: false,
				display: 'flex',
				renderCell: ({value}) => <Typography variant='body2' whiteSpace='wrap' py={1}>{value}</Typography>
			},
			{
				field: 'warrantyExpirationDate',
				headerName: upperFirst(
					t('maintenancePlanner:subheader-warranty-expiration-date')
				),
				flex: 2,
				type: 'date',
				minWidth: 120,
				display: 'flex',
				valueFormatter: (value) => getLocalizedDateFormat(value),
				sortable: false,
			},
		],
		[t]
	);

	return (
        <Box sx={{
            height: dataGridHeight
        }}>
            <LocalizedDataGrid
				columns={columns}
				density={density}
				disableColumnFilter
				disableColumnMenu
				disableRowSelectionOnClick
				getRowId={({ code, warrantyExpirationDate }: CoverageCodeDto) =>
					`${code}-${warrantyExpirationDate?.getTime()}`
				}
				hideFooter
				getRowHeight={() => 'auto'}
				rows={coverages}
				sx={sx}
			/>
        </Box>
    );
};