/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	AddNotificationTextCommand,
	AddOrChangeModuleProductIndividualCommand,
	BusinessPartnerDto,
	CheckIfEmailUsedQuery,
	ClaimHistoryInfoDto,
	CommentDto,
	CommissioningDto,
	ComponentAndSoftwareDto,
	ConcernedIndividualCampaignDto,
	ConnectedUnit,
	CountryInfoDto,
	CoverageCodeDto,
	CreateAdditionalFilesCommand,
	CreateAndConnectConsumersToUnitCommand,
	CreateForeignProductIndividualCommentCommand,
	CreateOrUpdateCommissioningCommand,
	CreateOrUpdateConsumerCommand,
	CreateOrUpdateUnitLocationCommand,
	CreateProductIndividualCommentCommand,
	CreateUpdateProductInOperationCommand,
	DemoUseDto,
	DocumentDto,
	DomainErrorResponse,
	DrivelineControlSystemInfo,
	DrivelineCoveragesDto,
	DrivelineViewDto,
	EditProductIndividualCommand,
	EditUnitConfigurationCommand,
	EndConsumerDto,
	EndConsumersSearchResponse,
	ErrorResponse,
	EvcUnitStatusResponse,
	EventLogDto,
	FinalizeOfflineServiceProtocolCommand,
	FinalizeOnlineServiceProtocolCommand,
	GetDrivelineMaintenancePlansQuery,
	GetServiceProtocolInitialDatesDto,
	IdLabelSortedDto,
	InstallationPurpose,
	InstallationTypeDto,
	Kit,
	ListItemDto,
	ListItemParentDto,
	MainSegment,
	MainSegmentNames,
	MaintenancePlansResponse,
	MenuDto,
	ModelDto,
	ModifyTreeAddPartForSaleCommand,
	ModifyTreeAddProductIndividualBranchCommand,
	ModifyTreeMatchOutOfCalloffProductsCommand,
	ModifyTreeMatchPartForSaleCommand,
	ModifyTreeMatchProductIndividualCommand,
	ModuleDto,
	NodeType,
	NotFoundErrorResponse,
	OrderInfoDto,
	OrderInfoProductIndividualViewDto,
	OwnerAndUnitManagerDto,
	PartForSaleDto,
	ProductInOperationDto,
	ProductInOperationResponse,
	ProductIndividualCampaignsContractDto,
	ProductIndividualEditDto,
	ProductIndividualItemPurposeExtended,
	ProductIndividualPartDto,
	ProductIndividualSearchDtoResult,
	ProductIndividualWithRelatedEntities,
	RegistrationItemDto,
	RepairDto,
	RepairHistoryDto,
	ReplaceProductIndividualCommand,
	ResetVmmCommand,
	ReviveProductIndividualCommand,
	SaveOnlineServiceProtocolDraftCommand,
	ScrapProductIndividualCommand,
	SearchProductIndividualDto,
	SearchProductMaintenancePlannerDto,
	SecurityMatrixDto,
	SelectItemDto,
	ServiceActivityEventsInfoDto,
	ServiceAgreementDto,
	ServiceDto,
	ServiceProtocolDto,
	SetUnitAsFavoriteCommand,
	SetUserLanguageCommand,
	SoftwareParameterDto,
	SwapIndividualInDrivelinesCommand,
	TechnicalInfoDto,
	TempFileUploadDto,
	UnitInfoDto,
	UnitInfoDtoBase,
	UnitOtherPartsDto,
	UnitRegisterCommand,
	UnitRegisterGRCommand,
	UnitRegistrationModelGR,
	UnitStructureDto,
	UpdateNotificationTextCommand,
	UpdateOtherPartsForUnitCommand,
	UserWithCustomerDto,
	VSTCodeDto,
	ValidationErrorResponse,
	ValidationFailure,
	VariantComparison,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class V1<
	SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
	/**
	 * @description Most of the time it is information about the upcoming update.
	 *
	 * @tags Configuration
	 * @name GetNotificationText
	 * @summary Get current notification text.
	 * @request GET:/v1/configuration/notification-text
	 * @secure
	 */
	getNotificationText = (params: RequestParams = {}) =>
		this.request<string, void | ErrorResponse>({
			path: `/v1/configuration/notification-text`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name AddNotificationText
	 * @summary Add new notification text
	 * @request POST:/v1/configuration/notification-text
	 * @secure
	 */
	addNotificationText = (
		data: AddNotificationTextCommand,
		params: RequestParams = {}
	) =>
		this.request<string, void | ErrorResponse>({
			path: `/v1/configuration/notification-text`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name UpdateNotificationText
	 * @summary Update notification text
	 * @request PUT:/v1/configuration/notification-text
	 * @secure
	 */
	updateNotificationText = (
		data: UpdateNotificationTextCommand,
		params: RequestParams = {}
	) =>
		this.request<string, ValidationErrorResponse | void | ErrorResponse>({
			path: `/v1/configuration/notification-text`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name RemoveNotificationText
	 * @summary Remove notification text
	 * @request DELETE:/v1/configuration/notification-text
	 * @secure
	 */
	removeNotificationText = (params: RequestParams = {}) =>
		this.request<void, void | ErrorResponse>({
			path: `/v1/configuration/notification-text`,
			method: 'DELETE',
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name GetHullIdStandards
	 * @summary Get standard Volvo hull id types
	 * @request GET:/v1/configuration/hull-id-standard
	 * @secure
	 */
	getHullIdStandards = (
		query: {
			countryCode: string;
		},
		params: RequestParams = {}
	) =>
		this.request<SelectItemDto[], void | ErrorResponse>({
			path: `/v1/configuration/hull-id-standard`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name GetLengthUnits
	 * @summary Get standard Volvo length units.
	 * @request GET:/v1/configuration/length-units
	 * @secure
	 */
	getLengthUnits = (
		query?: {
			countryCode?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<SelectItemDto[], void | ErrorResponse>({
			path: `/v1/configuration/length-units`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name GetWeightUnits
	 * @summary Get standard Volvo weight units.
	 * @request GET:/v1/configuration/weight-units
	 * @secure
	 */
	getWeightUnits = (
		query?: {
			countryCode?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<SelectItemDto[], void | ErrorResponse>({
			path: `/v1/configuration/weight-units`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name GetCountryList
	 * @summary Get list of all countries.
	 * @request GET:/v1/configuration/country-infos
	 * @secure
	 */
	getCountryList = (
		query?: {
			languageCountryCode?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<CountryInfoDto[], void | ErrorResponse>({
			path: `/v1/configuration/country-infos`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Configuration
	 * @name GetNotificationLanguages
	 * @summary Get list of Volvo GDPR notification languages.
	 * @request GET:/v1/configuration/notification-languages
	 * @secure
	 */
	getNotificationLanguages = (params: RequestParams = {}) =>
		this.request<string[], void | ErrorResponse>({
			path: `/v1/configuration/notification-languages`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Driveline
	 * @name GetDrivelineStructureById
	 * @summary Get driveline information with children structure.
	 * @request GET:/v1/driveline/{id}/structure
	 * @secure
	 */
	getDrivelineStructureById = (id: number, params: RequestParams = {}) =>
		this.request<
			DrivelineViewDto,
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/driveline/${id}/structure`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Driveline
	 * @name GetDrivelineId
	 * @summary Get driveline information with children structure.
	 * @request GET:/v1/driveline/id
	 * @secure
	 */
	getDrivelineId = (
		query: {
			chassisSeries: string;
			/** @format int32 */
			chassisNumber: number;
		},
		params: RequestParams = {}
	) =>
		this.request<number, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/driveline/id`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Driveline
	 * @name GetDrivelineVariants
	 * @summary Get driveline variants from VDA.
	 * @request GET:/v1/driveline/{id}/variants
	 * @secure
	 */
	getDrivelineVariants = (id: number, params: RequestParams = {}) =>
		this.request<
			VariantComparison,
			DomainErrorResponse | void | string | ErrorResponse
		>({
			path: `/v1/driveline/${id}/variants`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Response will only contain cancelled protocols, if the requestor has the access rights.
	 *
	 * @tags Driveline
	 * @name GetDrivelineServiceProtocols
	 * @summary Get service protocols for driveline.
	 * @request GET:/v1/driveline/{id}/service-protocol
	 * @secure
	 */
	getDrivelineServiceProtocols = (id: number, params: RequestParams = {}) =>
		this.request<
			ServiceProtocolDto[],
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/driveline/${id}/service-protocol`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Driveline
	 * @name GetDrivelineCoverages
	 * @summary Get list of driveline warranty coverages.
	 * @request GET:/v1/driveline/{id}/coverages
	 * @secure
	 */
	getDrivelineCoverages = (id: number, params: RequestParams = {}) =>
		this.request<CoverageCodeDto[], void | ErrorResponse>({
			path: `/v1/driveline/${id}/coverages`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description * Return campaigns filtered for dealer or internal user * Additionally returns link to campaign publication, but only the VPPC one.
	 *
	 * @tags Driveline
	 * @name GetDrivelineCampaignsWithoutPolicy
	 * @summary Return campaigns, except for policy type, for product individuals in the driveline.
	 * @request GET:/v1/driveline/{id}/campaigns
	 * @secure
	 */
	getDrivelineCampaignsWithoutPolicy = (
		id: number,
		query?: {
			/** If result set should contain concerned individuals with campaigns performed */
			includePerformed?: boolean;
			/** If result set should contain closed campaigns */
			includeClosed?: boolean;
			/** Get campaigns for internal roles or for external roles */
			queryAsExternal?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<ConcernedIndividualCampaignDto[], void | ErrorResponse>({
			path: `/v1/driveline/${id}/campaigns`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags EndCustomer
	 * @name GetConsumerById
	 * @summary Returns consumer/end customer information.
	 * @request GET:/v1/end-customer/{id}
	 * @secure
	 */
	getConsumerById = (id: number, params: RequestParams = {}) =>
		this.request<EndConsumerDto, void | ErrorResponse>({
			path: `/v1/end-customer/${id}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags EndCustomer
	 * @name DeleteCustomer
	 * @summary Removes consumer/end customer.
	 * @request DELETE:/v1/end-customer/{id}
	 * @secure
	 */
	deleteCustomer = (id: number, params: RequestParams = {}) =>
		this.request<void, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/end-customer/${id}`,
			method: 'DELETE',
			secure: true,
			...params,
		});
	/**
	 * @description Additional GDPR results masking is applied.
	 *
	 * @tags EndCustomer
	 * @name GetCustomers
	 * @summary Retrieves list of consumers/end customers, restricted to only 10 results, using filters is recommended.
	 * @request GET:/v1/end-customer
	 * @secure
	 */
	getCustomers = (
		query?: {
			email?: string | null;
			companyName?: string | null;
			vatNumber?: string | null;
			phonePrefix?: string | null;
			phoneNumber?: string | null;
			firstName?: string | null;
			surNameOrLastName?: string | null;
			county?: string | null;
			city?: string | null;
			zipCode?: string | null;
			address?: string | null;
			countryCode?: string | null;
		},
		params: RequestParams = {}
	) =>
		this.request<EndConsumersSearchResponse, void | ErrorResponse>({
			path: `/v1/end-customer`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags EndCustomer
	 * @name CreateOrUpdateCustomer
	 * @summary Create or update consumer/end customer.
	 * @request POST:/v1/end-customer
	 * @secure
	 */
	createOrUpdateCustomer = (
		data: CreateOrUpdateConsumerCommand,
		params: RequestParams = {}
	) =>
		this.request<
			EndConsumerDto,
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/end-customer`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Email has to be put in the body, as it is sensitive data
	 *
	 * @tags EndCustomer
	 * @name GetEmailIsNotUsed
	 * @summary Check if email is available, for consumer/end customer creation process
	 * @request POST:/v1/end-customer/email-availability
	 * @secure
	 */
	getEmailIsNotUsed = (
		data: CheckIfEmailUsedQuery,
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/end-customer/email-availability`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags File
	 * @name DownloadFile
	 * @summary Download file. POST request, to handle token passed in body
	 * @request POST:/v1/file
	 * @secure
	 */
	downloadFile = (
		query: {
			/** @format int32 */
			fileSetId: number;
			/** @format int32 */
			fileId: number;
		},
		params: RequestParams = {}
	) =>
		this.request<File, string | void | ErrorResponse>({
			path: `/v1/file`,
			method: 'POST',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags File
	 * @name DownloadDocument
	 * @summary Download document. POST request, to handle token passed in body
	 * @request POST:/v1/file/document/{id}
	 * @secure
	 */
	downloadDocument = (id: number, params: RequestParams = {}) =>
		this.request<File, string | void | ErrorResponse>({
			path: `/v1/file/document/${id}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags File
	 * @name DownloadServiceProtocolFile
	 * @summary Download service protocol file. POST request, to handle token passed in body
	 * @request POST:/v1/file/service-protocol/{fileId}
	 * @secure
	 */
	downloadServiceProtocolFile = (
		fileId: number,
		params: RequestParams = {}
	) =>
		this.request<File, string | void | ErrorResponse>({
			path: `/v1/file/service-protocol/${fileId}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags File
	 * @name DownloadPerformedOperationImage
	 * @summary Download service protocol performed operation image
	 * @request GET:/v1/file/performed-operation-image/{imageId}
	 * @secure
	 */
	downloadPerformedOperationImage = (
		imageId: number,
		query?: {
			/** Which thumbnail size should be returned. */
			size?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<File, string | void | ErrorResponse>({
			path: `/v1/file/performed-operation-image/${imageId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags File
	 * @name DownloadImage
	 * @summary Download picture
	 * @request GET:/v1/file/image/{id}
	 * @secure
	 */
	downloadImage = (
		id: number,
		query?: {
			/** Which thumbnail size should be returned. */
			size?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<File, string | void | ErrorResponse>({
			path: `/v1/file/image/${id}`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description * Can be used during registration process and later sent paths, when finishing process. * Is available for any authenticated user. * If this is first file, pass empty value for attachment folder. For any subsequent file use returned value from first request.
	 *
	 * @tags File
	 * @name UploadTemporaryFile
	 * @summary UploadTemporaryFile temporary document.
	 * @request POST:/v1/file/upload-temporary-file
	 * @secure
	 */
	uploadTemporaryFile = (
		data: {
			/** @format binary */
			file?: File;
		},
		query?: {
			/** Attachment folder. */
			attachmentsFolder?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<TempFileUploadDto, string | void | ErrorResponse>({
			path: `/v1/file/upload-temporary-file`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * @description Is available for any authenticated user.
	 *
	 * @tags File
	 * @name UploadErrorFile
	 * @summary UploadTemporaryFile error file generated by FE application
	 * @request POST:/v1/file/upload-error-file
	 * @secure
	 */
	uploadErrorFile = (
		data: {
			/** @format binary */
			file?: File;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, string | void | ErrorResponse>({
			path: `/v1/file/upload-error-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * @description * Can be used during registration process, after which folder name can be sent as parameter. * If this is first file, pass empty value for attachment folder. * For any subsequent file use returned value from first request.
	 *
	 * @tags File
	 * @name UploadTemporaryPicture
	 * @summary UploadTemporaryFile picture to temporary folder.
	 * @request POST:/v1/file/upload-temporary-picture
	 * @secure
	 */
	uploadTemporaryPicture = (
		data: {
			/** @format binary */
			image?: File;
		},
		query?: {
			/** Attachment folder */
			attachmentsFolder?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<TempFileUploadDto, string | void | ErrorResponse>({
			path: `/v1/file/upload-temporary-picture`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags GuidedRegistration
	 * @name SearchIndividual
	 * @summary Get candidate Position and Entity tree of a new Unit based on Blueprint
	 * @request GET:/v1/GuidedRegistration/SearchIndividual
	 * @secure
	 */
	searchIndividual = (
		query?: {
			/** Serial number of the ProductIndividual that is part of new Unit */
			serialNumber?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<UnitRegistrationModelGR, string | void | ErrorResponse>({
			path: `/v1/GuidedRegistration/SearchIndividual`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags GuidedRegistration
	 * @name SearchIndividualOnly
	 * @summary Get Product Individual for ad-hoc match by SerialNumber
	 * @request GET:/v1/GuidedRegistration/SearchIndividualOnly
	 * @secure
	 */
	searchIndividualOnly = (
		query?: {
			/** Serial number of the ProductIndividual that is part of new Unit */
			serialNumber?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<
			ProductIndividualWithRelatedEntities,
			string | void | ErrorResponse
		>({
			path: `/v1/GuidedRegistration/SearchIndividualOnly`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags GuidedRegistration
	 * @name RegisterUnitTree
	 * @summary Register new unit.
	 * @request POST:/v1/GuidedRegistration/RegisterUnitTree
	 * @secure
	 */
	registerUnitTree = (
		data: UnitRegisterGRCommand,
		params: RequestParams = {}
	) =>
		this.request<number, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/GuidedRegistration/RegisterUnitTree`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
 * No description
 *
 * @tags GuidedRegistration
 * @name ModifyTreeMatchProductIndividual
 * @summary Send in the Registration Model and list of ProductIndividual matches to be applied to it (sequentially).
Each match contains Position Id and matched ProductIndividual.
 * @request POST:/v1/GuidedRegistration/ModifyTree/MatchProductIndividual
 */
	modifyTreeMatchProductIndividual = (
		data: ModifyTreeMatchProductIndividualCommand,
		params: RequestParams = {}
	) =>
		this.request<UnitRegistrationModelGR, string | ErrorResponse>({
			path: `/v1/GuidedRegistration/ModifyTree/MatchProductIndividual`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
 * No description
 *
 * @tags GuidedRegistration
 * @name ModifyTreeMatchPartForSale
 * @summary Send in the Registration Model and list of PartForSales matches to be applied to it (sequentially).
Each match contains Position Id and matched PartForSale.
 * @request POST:/v1/GuidedRegistration/ModifyTree/MatchPartForSale
 */
	modifyTreeMatchPartForSale = (
		data: ModifyTreeMatchPartForSaleCommand,
		params: RequestParams = {}
	) =>
		this.request<UnitRegistrationModelGR, string | ErrorResponse>({
			path: `/v1/GuidedRegistration/ModifyTree/MatchPartForSale`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags GuidedRegistration
	 * @name ModifyTreeMatchOutOfCalloffProductCommand
	 * @summary Send in the Registration Model and an arbitrary ProductIndividual to be matched to specific position
	 * @request POST:/v1/GuidedRegistration/ModifyTree/MatchOutOfCalloffProductIndividual
	 */
	modifyTreeMatchOutOfCalloffProductCommand = (
		data: ModifyTreeMatchOutOfCalloffProductsCommand,
		params: RequestParams = {}
	) =>
		this.request<UnitRegistrationModelGR, string | ErrorResponse>({
			path: `/v1/GuidedRegistration/ModifyTree/MatchOutOfCalloffProductIndividual`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags GuidedRegistration
	 * @name ModifyTreeAddProductIndividualBranch
	 * @summary Send in the Registration Model, Product Individual with related Entities and Position Id to append new subtree to
	 * @request POST:/v1/GuidedRegistration/ModifyTree/AddProductIndividualBranch
	 */
	modifyTreeAddProductIndividualBranch = (
		data: ModifyTreeAddProductIndividualBranchCommand,
		params: RequestParams = {}
	) =>
		this.request<UnitRegistrationModelGR, string | ErrorResponse>({
			path: `/v1/GuidedRegistration/ModifyTree/AddProductIndividualBranch`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags GuidedRegistration
	 * @name ModifyTreeAddPartForSale
	 * @summary Send in the Registration Model, PartForSale and Position Id to append new subtree to
	 * @request POST:/v1/GuidedRegistration/ModifyTree/AddPartForSale
	 */
	modifyTreeAddPartForSale = (
		data: ModifyTreeAddPartForSaleCommand,
		params: RequestParams = {}
	) =>
		this.request<UnitRegistrationModelGR, string | ErrorResponse>({
			path: `/v1/GuidedRegistration/ModifyTree/AddPartForSale`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Installation
	 * @name GetPartsForSales
	 * @summary GET sales order parts attached to product individuals.
	 * @request GET:/v1/installation/part-for-sales
	 * @secure
	 */
	getPartsForSales = (
		query?: {
			/** List of product individuals serial numbers. */
			'serialNumbers[]'?: string[];
		},
		params: RequestParams = {}
	) =>
		this.request<
			PartForSaleDto[],
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/installation/part-for-sales`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Installation
	 * @name GetTravelReimbursementUnits
	 * @summary GET travel reimbursement units for commissioning.
	 * @request GET:/v1/installation/travel-reimbursement-units
	 * @secure
	 */
	getTravelReimbursementUnits = (params: RequestParams = {}) =>
		this.request<string[], void | ErrorResponse>({
			path: `/v1/installation/travel-reimbursement-units`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Installation
	 * @name GetBrandsByMainSegment
	 * @summary GET Brands by MainSegment like M or I. By default returns marine brands, in case of any errors.
	 * @request GET:/v1/installation/brands-by-main-segment
	 * @secure
	 */
	getBrandsByMainSegment = (
		query?: {
			mainSegment?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<
			SelectItemDto[],
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/installation/brands-by-main-segment`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Installation
	 * @name GetModelsByBrandId
	 * @summary GET Models by BrandId
	 * @request GET:/v1/installation/models
	 * @secure
	 */
	getModelsByBrandId = (
		query?: {
			/** @format int32 */
			brandId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<ModelDto[], DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/installation/models`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Installation
	 * @name GetDemoUse
	 * @summary GET demo use by Id
	 * @request GET:/v1/installation/demo-use
	 * @secure
	 */
	getDemoUse = (
		query?: {
			/** @format int32 */
			id?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<DemoUseDto, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/installation/demo-use`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Installation
	 * @name GetDemoUses
	 * @summary GET demo uses for Unit
	 * @request GET:/v1/installation/demo-uses
	 * @secure
	 */
	getDemoUses = (
		query?: {
			/** @format int32 */
			unitId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<DemoUseDto[], DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/installation/demo-uses`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Sorting, filtering and paging is implemented. Use the PageIndex to retrieve proper page, TotalResults are only sent in the first page. User with access rights can additionally pass custom customer number.
	 *
	 * @tags MaintenancePlanner
	 * @name GetDealerMaintenanceUnits
	 * @summary Get user maintenance dashboard items.
	 * @request POST:/v1/maintenance-planner/dealer-units
	 * @secure
	 */
	getDealerMaintenanceUnits = (
		data: GetDrivelineMaintenancePlansQuery,
		params: RequestParams = {}
	) =>
		this.request<
			MaintenancePlansResponse,
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/maintenance-planner/dealer-units`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description User with access rights can additionally pass custom customer number.
	 *
	 * @tags MaintenancePlanner
	 * @name AddItemToCustomerMaintenancePlannerList
	 * @summary Add unit or driveline to user maintenance dashboard items.
	 * @request POST:/v1/maintenance-planner/dealer-drivelines
	 * @secure
	 */
	addItemToCustomerMaintenancePlannerList = (
		query?: {
			chassisId?: string | null;
			/** @format int64 */
			unitId?: number | null;
			/** @format int64 */
			customerNumber?: number | null;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/maintenance-planner/dealer-drivelines`,
			method: 'POST',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description User with access rights can additionally pass custom customer number.
	 *
	 * @tags MaintenancePlanner
	 * @name RemoveItemFromCustomerMaintenancePlannerList
	 * @summary Remove unit or driveline from user maintenance dashboard items.
	 * @request DELETE:/v1/maintenance-planner/dealer-drivelines
	 * @secure
	 */
	removeItemFromCustomerMaintenancePlannerList = (
		query?: {
			chassisId?: string | null;
			/** @format int64 */
			unitId?: number | null;
			/** @format int64 */
			customerNumber?: number | null;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/maintenance-planner/dealer-drivelines`,
			method: 'DELETE',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description User with access rights can additionally pass custom customer number.
	 *
	 * @tags MaintenancePlanner
	 * @name ScheduleMaintenanceServiceDate
	 * @summary Schedule service date for unit/driveline.
	 * @request POST:/v1/maintenance-planner/service-date/schedule
	 * @secure
	 */
	scheduleMaintenanceServiceDate = (
		query: {
			/** @format date-time */
			serviceDate: Date;
			chassisId?: string | null;
			/** @format int64 */
			unitId?: number | null;
			/** @format int64 */
			customerNumber?: number | null;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/maintenance-planner/service-date/schedule`,
			method: 'POST',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description User with access rights can additionally pass custom customer number.
	 *
	 * @tags MaintenancePlanner
	 * @name DeleteMaintenanceServiceDate
	 * @summary Delete scheduled service date for unit/driveline.
	 * @request DELETE:/v1/maintenance-planner/service-date/delete
	 * @secure
	 */
	deleteMaintenanceServiceDate = (
		query?: {
			chassisId?: string | null;
			/** @format int64 */
			unitId?: number | null;
			/** @format int64 */
			customerNumber?: number | null;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/maintenance-planner/service-date/delete`,
			method: 'DELETE',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags MaintenancePlanner
	 * @name GetMaintenanceUnitsBySearch
	 * @summary Search for units and drivelines by end customer id or by search phrase.
	 * @request GET:/v1/maintenance-planner/search
	 * @secure
	 */
	getMaintenanceUnitsBySearch = (
		query?: {
			/** @format int32 */
			consumerId?: number | null;
			searchPhrase?: string | null;
			/** @format int64 */
			businessPartnerId?: number | null;
		},
		params: RequestParams = {}
	) =>
		this.request<
			SearchProductMaintenancePlannerDto[],
			void | ErrorResponse
		>({
			path: `/v1/maintenance-planner/search`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Order
	 * @name GetOrder
	 * @summary Get order information.
	 * @request GET:/v1/order/{orderId}
	 * @secure
	 */
	getOrder = (
		orderId: number,
		query?: {
			/** Serial Number of individual, needed for ship and invoice dates */
			serialNumber?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<
			OrderInfoDto,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/order/${orderId}`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Order
	 * @name GetOrderPartsForSale
	 * @summary Get parts from order.
	 * @request GET:/v1/order/{orderId}/other-parts
	 * @secure
	 */
	getOrderPartsForSale = (orderId: number, params: RequestParams = {}) =>
		this.request<
			PartForSaleDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/order/${orderId}/other-parts`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Order
	 * @name GetOrderProductIndividuals
	 * @summary Get product individuals from order.
	 * @request GET:/v1/order/{orderId}/product-individuals
	 * @secure
	 */
	getOrderProductIndividuals = (
		orderId: number,
		params: RequestParams = {}
	) =>
		this.request<
			OrderInfoProductIndividualViewDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/order/${orderId}/product-individuals`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Order
	 * @name GetOrderServices
	 * @summary Get services for order.
	 * @request GET:/v1/order/{orderId}/services
	 * @secure
	 */
	getOrderServices = (orderId: number, params: RequestParams = {}) =>
		this.request<
			ServiceDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/order/${orderId}/services`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Pdf
	 * @name DownloadServiceProtocolPdf
	 * @summary Creates PDF printout of the service protocol
	 * @request POST:/v1/pdf/service-protocol
	 * @secure
	 */
	downloadServiceProtocolPdf = (
		data: {
			isVppnRequest?: boolean;
			/** @format int32 */
			id?: number;
			/** @format date-time */
			protocolGenerationClientDate?: Date;
			additionalText?: string;
			printQr?: boolean;
			printConfiguration?: boolean;
			printEndCustomer?: boolean;
			printCampaigns?: boolean;
			printCoverage?: boolean;
			pritntProposals?: boolean;
			printEstimatedHours?: boolean;
			printUsedHours?: boolean;
			printActivitiesPhotos?: boolean;
			printAdditionalRepairs?: boolean;
			printAdditionalRepairPhotos?: boolean;
			printSignaures?: boolean;
		},
		query?: {
			/**
			 * Should file be opened by browser or not.
			 * @default false
			 */
			openFile?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<File, string | void | ErrorResponse>({
			path: `/v1/pdf/service-protocol`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetForeignProductCategories
	 * @summary Get foreign products categories.
	 * @request GET:/v1/product/foreign-product-categories
	 * @secure
	 */
	getForeignProductCategories = (params: RequestParams = {}) =>
		this.request<
			IdLabelSortedDto[],
			ValidationErrorResponse | void | ErrorResponse
		>({
			path: `/v1/product/foreign-product-categories`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetRegistrationPart
	 * @summary Get properties for an item.
	 * @request GET:/v1/product/registration-part
	 * @secure
	 */
	getRegistrationPart = (
		query?: {
			/** Part number */
			partNumber?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<RegistrationItemDto, void | ErrorResponse>({
			path: `/v1/product/registration-part`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetDetailedSegments
	 * @summary Get detailed segments for specified main segment.
	 * @request GET:/v1/product/detailed-segments
	 * @secure
	 */
	getDetailedSegments = (
		query?: {
			mainSegment?: MainSegmentNames | null;
		},
		params: RequestParams = {}
	) =>
		this.request<ListItemDto[], void | ErrorResponse>({
			path: `/v1/product/detailed-segments`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetLineOfBusinesses
	 * @summary Retrieves line of business for specified main segment.
	 * @request GET:/v1/product/line-of-business
	 * @secure
	 */
	getLineOfBusinesses = (
		query: {
			mainSegment: MainSegmentNames;
		},
		params: RequestParams = {}
	) =>
		this.request<ListItemDto[], string | void | ErrorResponse>({
			path: `/v1/product/line-of-business`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetUnitPurposes
	 * @summary Retrieves list of unit purposes.
	 * @request GET:/v1/product/unit-purposes
	 * @secure
	 */
	getUnitPurposes = (params: RequestParams = {}) =>
		this.request<ListItemParentDto[], void | ErrorResponse>({
			path: `/v1/product/unit-purposes`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetInstallationPurposes
	 * @summary Retrieves installation purposes for specified detailed segment.
	 * @request GET:/v1/product/installation-purposes
	 * @secure
	 */
	getInstallationPurposes = (
		query: {
			detailedSegment: string;
		},
		params: RequestParams = {}
	) =>
		this.request<InstallationTypeDto[], void | ErrorResponse>({
			path: `/v1/product/installation-purposes`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetOperatingProfiles
	 * @summary Retrieves operating profiles for specified installation purpose.
	 * @request GET:/v1/product/operating-profiles
	 * @secure
	 */
	getOperatingProfiles = (
		query: {
			installationPurpose: InstallationPurpose;
		},
		params: RequestParams = {}
	) =>
		this.request<ListItemDto[], void | ErrorResponse>({
			path: `/v1/product/operating-profiles`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Product
	 * @name GetPartDescriptionForModule
	 * @summary Get description characteristics for specified part.
	 * @request GET:/v1/product/module
	 * @secure
	 */
	getPartDescriptionForModule = (
		query?: {
			/** Part number */
			partNumber?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<string, void | ErrorResponse>({
			path: `/v1/product/module`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description PC.FE.API.Application.DTO.SearchProductIndividual.ProductIndividualSearchDtoResult.InfoSource contains information if product is a part of bigger structure.
	 *
	 * @tags ProductIndividual
	 * @name SearchProductIndividualBySerialNumber
	 * @summary Search for product individual by serial number, that can be used in the unit registration process.
	 * @request GET:/v1/product-individual/search
	 * @secure
	 */
	searchProductIndividualBySerialNumber = (
		query?: {
			/** Product individual serial number */
			serialNumber?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<ProductIndividualSearchDtoResult, void | ErrorResponse>({
			path: `/v1/product-individual/search`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name SearchForProducts
	 * @summary Search for units by search phrase.
	 * @request GET:/v1/product-individual/search-with-structure
	 * @secure
	 */
	searchForProducts = (
		query: {
			searchPhrase: string;
		},
		params: RequestParams = {}
	) =>
		this.request<SearchProductIndividualDto[], void | ErrorResponse>({
			path: `/v1/product-individual/search-with-structure`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetProductIndividualIdBySerialNumber
	 * @summary Return id of product individual with the specified serial number.
	 * @request GET:/v1/product-individual/{serialNumber}/id
	 * @secure
	 */
	getProductIndividualIdBySerialNumber = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<number, void | ErrorResponse>({
			path: `/v1/product-individual/${serialNumber}/id`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetProductIndividualComments
	 * @summary Return list of comments added to product individual.
	 * @request GET:/v1/product-individual/{serialNumber}/comment
	 * @secure
	 */
	getProductIndividualComments = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			CommentDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/comment`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetForeignProductIndividualComments
	 * @summary Return list of comments added to foreign product individual.
	 * @request GET:/v1/product-individual/{id}/foreign-comment
	 * @secure
	 */
	getForeignProductIndividualComments = (
		id: number,
		params: RequestParams = {}
	) =>
		this.request<
			CommentDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${id}/foreign-comment`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetProductIndividualDocuments
	 * @summary Return list of documents attached to the product individual.
	 * @request GET:/v1/product-individual/{serialNumber}/documents
	 * @secure
	 */
	getProductIndividualDocuments = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			DocumentDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/documents`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name AddProductIndividualComment
	 * @summary Add new comment to product individual.
	 * @request POST:/v1/product-individual/comment
	 * @secure
	 */
	addProductIndividualComment = (
		data: CreateProductIndividualCommentCommand,
		params: RequestParams = {}
	) =>
		this.request<
			number,
			| ValidationErrorResponse
			| void
			| NotFoundErrorResponse
			| ErrorResponse
		>({
			path: `/v1/product-individual/comment`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name AddForeignProductIndividualComment
	 * @summary Add new comment to foreign product individual.
	 * @request POST:/v1/product-individual/foreign-comment
	 * @secure
	 */
	addForeignProductIndividualComment = (
		data: CreateForeignProductIndividualCommentCommand,
		params: RequestParams = {}
	) =>
		this.request<
			number,
			| ValidationErrorResponse
			| void
			| NotFoundErrorResponse
			| ErrorResponse
		>({
			path: `/v1/product-individual/foreign-comment`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Results contains information if product belongs to any unit or a driveline.
	 *
	 * @tags ProductIndividual
	 * @name GetProductIndividualBySerialNumber
	 * @summary Return product individual with additional information.
	 * @request GET:/v1/product-individual/{serialNumber}
	 * @secure
	 */
	getProductIndividualBySerialNumber = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			ProductIndividualItemPurposeExtended,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetEditProductIndividualBySerialNumber
	 * @summary Return product individual with additional information for edit.
	 * @request GET:/v1/product-individual/{serialNumber}/edit
	 * @secure
	 */
	getEditProductIndividualBySerialNumber = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			ProductIndividualEditDto,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetProductIndividualById
	 * @summary Return product individual with additional information.
	 * @request GET:/v1/product-individual
	 * @secure
	 */
	getProductIndividualById = (
		query?: {
			/** @format int64 */
			id?: number | null;
			serialNumber?: string | null;
		},
		params: RequestParams = {}
	) =>
		this.request<
			ProductIndividualItemPurposeExtended,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Only for internal developers use.
	 *
	 * @tags ProductIndividual
	 * @name RemoveCommentById
	 * @summary Delete specified comment.
	 * @request DELETE:/v1/product-individual/comment/{id}
	 * @secure
	 */
	removeCommentById = (id: number, params: RequestParams = {}) =>
		this.request<void, void | ErrorResponse>({
			path: `/v1/product-individual/comment/${id}`,
			method: 'DELETE',
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name ForceSyncIndividual
	 * @summary Run synchronization job for product individual.
	 * @request POST:/v1/product-individual/{serialNumber}/force-sync
	 * @secure
	 */
	forceSyncIndividual = (serialNumber: string, params: RequestParams = {}) =>
		this.request<boolean, void | NotFoundErrorResponse | ErrorResponse>({
			path: `/v1/product-individual/${serialNumber}/force-sync`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description * Return campaigns filtered for dealer or internal user * Additionally returns link to campaign publication, but only the VPPC one.
	 *
	 * @tags ProductIndividual
	 * @name GetCampaignsWithoutPolicy
	 * @summary Return campaigns, except for policy type, for product individuals in the unit.
	 * @request GET:/v1/product-individual/{serialNumber}/campaigns
	 * @secure
	 */
	getCampaignsWithoutPolicy = (
		serialNumber: string,
		query?: {
			/** If result set should contain concerned individuals with campaigns performed */
			includePerformed?: boolean;
			/** If result set should contain closed campaigns */
			includeClosed?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<ConcernedIndividualCampaignDto[], void | ErrorResponse>({
			path: `/v1/product-individual/${serialNumber}/campaigns`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Return campaigns filtered for dealer or internal user
	 *
	 * @tags ProductIndividual
	 * @name GetCampaignsForProductIndividual
	 * @summary Return number of campaigns for individual, except for policy type.
	 * @request GET:/v1/product-individual/{serialNumber}/campaigns-count
	 * @secure
	 */
	getCampaignsForProductIndividual = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			ProductIndividualCampaignsContractDto,
			void | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/campaigns-count`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description * Return campaigns filtered for dealer or internal user * Additionally returns link to campaign publication, but only the VPPC one.
	 *
	 * @tags ProductIndividual
	 * @name GetOutstandingCampaignsOnlyPolicy
	 * @summary Return active campaigns, only of policy type, for product individuals in the unit.
	 * @request GET:/v1/product-individual/{serialNumber}/campaign-policy
	 * @secure
	 */
	getOutstandingCampaignsOnlyPolicy = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<ConcernedIndividualCampaignDto[], void | ErrorResponse>({
			path: `/v1/product-individual/${serialNumber}/campaign-policy`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetClaimHistoryForIndividual
	 * @summary Return information about product individual claims history.
	 * @request GET:/v1/product-individual/{serialNumber}/claims
	 * @secure
	 */
	getClaimHistoryForIndividual = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			ClaimHistoryInfoDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/claims`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetDetailedClaimInfo
	 * @summary Return detailed information about claim job and parent claim header
	 * @request GET:/v1/product-individual/claims
	 * @secure
	 */
	getDetailedClaimInfo = (
		query?: {
			/**
			 * Id for claim job
			 * @format int32
			 */
			claimJobId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<ClaimHistoryInfoDto, void | ErrorResponse>({
			path: `/v1/product-individual/claims`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetModulesForIndividual
	 * @summary Return modules configuration for product individual.
	 * @request GET:/v1/product-individual/{serialNumber}/module
	 * @secure
	 */
	getModulesForIndividual = (
		serialNumber: string,
		query?: {
			/** only after market modules */
			afterMarket?: boolean;
			/** only as sold modules */
			asSold?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<ModuleDto[], void | NotFoundErrorResponse | ErrorResponse>(
			{
				path: `/v1/product-individual/${serialNumber}/module`,
				method: 'GET',
				query: query,
				secure: true,
				format: 'json',
				...params,
			}
		);
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name AddOrChangeIndividualModule
	 * @summary Add or replace module in product individual.
	 * @request POST:/v1/product-individual/{serialNumber}/module
	 * @secure
	 */
	addOrChangeIndividualModule = (
		serialNumber: string,
		data: AddOrChangeModuleProductIndividualCommand,
		params: RequestParams = {}
	) =>
		this.request<
			number,
			| ValidationErrorResponse
			| void
			| NotFoundErrorResponse
			| ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/module`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name RemoveIndividualModule
	 * @summary Delete module in product individual.
	 * @request DELETE:/v1/product-individual/{serialNumber}/module
	 * @secure
	 */
	removeIndividualModule = (
		serialNumber: string,
		query?: {
			/** Number of module to be deleted */
			moduleNumber?: string;
			/** Reference field? */
			reference?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<number, void | NotFoundErrorResponse | ErrorResponse>({
			path: `/v1/product-individual/${serialNumber}/module`,
			method: 'DELETE',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetEventLogsForIndividual
	 * @summary Return events logs for specified product individual.
	 * @request GET:/v1/product-individual/{serialNumber}/event-logs
	 * @secure
	 */
	getEventLogsForIndividual = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<EventLogDto[], void | ErrorResponse>({
			path: `/v1/product-individual/${serialNumber}/event-logs`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetLogisticTrailForIndividual
	 * @summary Get logistic trail for product individual, mostly orders information.
	 * @request GET:/v1/product-individual/{serialNumber}/order
	 * @secure
	 */
	getLogisticTrailForIndividual = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			OrderInfoDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/order`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetVstCodes
	 * @summary Retrieves list of the VST codes for individual product.
	 * @request GET:/v1/product-individual/{serialNumber}/codes
	 * @secure
	 */
	getVstCodes = (serialNumber: string, params: RequestParams = {}) =>
		this.request<
			VSTCodeDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/codes`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetPartsListForIndividual
	 * @summary Return information about product individual parts (received from factory).
	 * @request GET:/v1/product-individual/{serialNumber}/parts
	 * @secure
	 */
	getPartsListForIndividual = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			ProductIndividualPartDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/parts`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name GetTechnicalInfoForIndividual
	 * @summary Return information about product individual technical information.
	 * @request GET:/v1/product-individual/{serialNumber}/technical-info
	 * @secure
	 */
	getTechnicalInfoForIndividual = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			TechnicalInfoDto,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/technical-info`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
 * No description
 *
 * @tags ProductIndividual
 * @name AssignTemporaryChassis
 * @summary Adds the product individual to the temporary driveline. 
Sets the expiration date of the driveline connection to 90 days.
 * @request POST:/v1/product-individual/{serialNumber}/assign-temporary-chassis
 * @secure
 */
	assignTemporaryChassis = (
		serialNumber: string,
		params: RequestParams = {}
	) =>
		this.request<
			number,
			| ValidationErrorResponse
			| void
			| NotFoundErrorResponse
			| ErrorResponse
		>({
			path: `/v1/product-individual/${serialNumber}/assign-temporary-chassis`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name ScrapProductIndividual
	 * @summary Change product individual status to scrapped.
	 * @request POST:/v1/product-individual/scrap
	 * @secure
	 */
	scrapProductIndividual = (
		data: ScrapProductIndividualCommand,
		params: RequestParams = {}
	) =>
		this.request<number, ValidationErrorResponse | void | ErrorResponse>({
			path: `/v1/product-individual/scrap`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ProductIndividual
	 * @name ReviveProductIndividual
	 * @summary Change product individual status back from scrapped or replaced.
	 * @request POST:/v1/product-individual/revive
	 * @secure
	 */
	reviveProductIndividual = (
		data: ReviveProductIndividualCommand,
		params: RequestParams = {}
	) =>
		this.request<number, ValidationErrorResponse | void | ErrorResponse>({
			path: `/v1/product-individual/revive`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Save individual changes accoridng to recieved data
	 *
	 * @tags ProductIndividual
	 * @name EditIndividualConfiguration
	 * @summary Update product individual.
	 * @request POST:/v1/product-individual/edit
	 * @secure
	 */
	editIndividualConfiguration = (
		data: EditProductIndividualCommand,
		params: RequestParams = {}
	) =>
		this.request<void, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/product-individual/edit`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Repair
	 * @name GetRepair
	 * @summary Returns repair event details
	 * @request GET:/v1/repair/{repairId}
	 * @secure
	 */
	getRepair = (repairId: number, params: RequestParams = {}) =>
		this.request<RepairDto, void | ErrorResponse>({
			path: `/v1/repair/${repairId}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Repair
	 * @name DeleteRepair
	 * @summary Delete repair event details
	 * @request DELETE:/v1/repair/{repairId}
	 * @secure
	 */
	deleteRepair = (repairId: number, params: RequestParams = {}) =>
		this.request<boolean, void | ErrorResponse>({
			path: `/v1/repair/${repairId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Repair
	 * @name GetRepairs
	 * @summary Returns repairs event details list
	 * @request GET:/v1/repair/{serialNumber}/list
	 * @secure
	 */
	getRepairs = (serialNumber: string, params: RequestParams = {}) =>
		this.request<RepairHistoryDto[], void | ErrorResponse>({
			path: `/v1/repair/${serialNumber}/list`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Security
	 * @name GetUser
	 * @summary Get requestor user data.
	 * @request GET:/v1/security/user
	 * @secure
	 */
	getUser = (params: RequestParams = {}) =>
		this.request<
			UserWithCustomerDto,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/security/user`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Security
	 * @name GetSecurityMatrix
	 * @summary Get requestor security matrix
	 * @request GET:/v1/security/user-security-matrix
	 * @secure
	 */
	getSecurityMatrix = (params: RequestParams = {}) =>
		this.request<SecurityMatrixDto[], void | ErrorResponse>({
			path: `/v1/security/user-security-matrix`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Security
	 * @name GetMenu
	 * @summary Get requestor application menu.
	 * @request GET:/v1/security/user-menu
	 * @secure
	 */
	getMenu = (
		query?: {
			/** Pass true if the request is coming from VPPN. */
			isExternal?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<MenuDto[], void | ErrorResponse>({
			path: `/v1/security/user-menu`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Security
	 * @name GetSupportedLanguageList
	 * @summary Get supported language list for select language change
	 * @request GET:/v1/security/language-list
	 * @secure
	 */
	getSupportedLanguageList = (params: RequestParams = {}) =>
		this.request<Record<string, string>, void | ErrorResponse>({
			path: `/v1/security/language-list`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Security
	 * @name SetUserLanguage
	 * @summary Set user language for requestor.
	 * @request POST:/v1/security/user-language
	 * @secure
	 */
	setUserLanguage = (
		data: SetUserLanguageCommand,
		params: RequestParams = {}
	) =>
		this.request<
			void,
			string | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/security/user-language`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Security
	 * @name SearchBusinessPartners
	 * @summary Search for business partners using prompt.
	 * @request GET:/v1/security/business-partners
	 * @secure
	 */
	searchBusinessPartners = (
		query?: {
			/** Search prompt, minimum 3 characters required */
			prompt?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<BusinessPartnerDto[], void | ErrorResponse>({
			path: `/v1/security/business-partners`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name CancelServiceProtocol
	 * @summary Cancels finalized service protocol. Reverts current location and operating hours changes on product individuals and unit.
	 * @request POST:/v1/service-protocol/{id}/cancel
	 * @secure
	 */
	cancelServiceProtocol = (id: number, params: RequestParams = {}) =>
		this.request<
			void,
			| ValidationErrorResponse
			| void
			| NotFoundErrorResponse
			| ErrorResponse
		>({
			path: `/v1/service-protocol/${id}/cancel`,
			method: 'POST',
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name DeleteServiceProtocol
	 * @summary Deletes initiated service protocol.
	 * @request DELETE:/v1/service-protocol/{id}
	 * @secure
	 */
	deleteServiceProtocol = (id: number, params: RequestParams = {}) =>
		this.request<
			void,
			| ValidationErrorResponse
			| void
			| NotFoundErrorResponse
			| ErrorResponse
		>({
			path: `/v1/service-protocol/${id}`,
			method: 'DELETE',
			secure: true,
			...params,
		});
	/**
	 * @description Response will only contain cancelled protocol, if the requestor has the access rights.
	 *
	 * @tags ServiceProtocol
	 * @name GetServiceProtocol
	 * @summary Get service protocol
	 * @request GET:/v1/service-protocol/{id}
	 * @secure
	 */
	getServiceProtocol = (
		id: number,
		query?: {
			/** Should include service activity events in the response */
			includeEvents?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<
			ServiceProtocolDto,
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/service-protocol/${id}`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name GetLastInitiatedProtocolId
	 * @summary Returns last initiated service protocol id for requested chassis.
	 * @request GET:/v1/service-protocol/last-initiated-protocol-id
	 * @secure
	 */
	getLastInitiatedProtocolId = (
		query?: {
			/** Chassis identification */
			chassisId?: string;
		},
		params: RequestParams = {}
	) =>
		this.request<number, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/last-initiated-protocol-id`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Response will only contain cancelled protocol, if the requestor has the access rights.
	 *
	 * @tags ServiceProtocol
	 * @name GetServiceContent
	 * @summary Get service content
	 * @request GET:/v1/service-protocol/service-activities
	 * @secure
	 */
	getServiceContent = (
		query: {
			chassisSeries: string;
			/** @format int32 */
			chassisNumber: number;
			/** @format date-time */
			serviceDate: Date;
			/** @format date-time */
			productInOperationDate: Date;
			/** @format int32 */
			currentEngineHours: number;
			/**
			 * Not required, if there was no previous service protocol. Although user can still pass some date, if protocol was not registered in our system.
			 * @format date-time
			 */
			lastServiceDate?: Date | null;
			/** @format int32 */
			lastServiceEngineHours?: number | null;
			/** Preferred language for service protocol, will be used for displaying data and printing PDF. By default set to american english. */
			preferredLanguage: string;
		},
		params: RequestParams = {}
	) =>
		this.request<
			ServiceActivityEventsInfoDto,
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/service-protocol/service-activities`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Response will return information if service content is available for chassis.
	 *
	 * @tags ServiceProtocol
	 * @name IsServiceContentAvailable
	 * @summary Get service content
	 * @request GET:/v1/service-protocol/service-activities-chassis
	 * @secure
	 */
	isServiceContentAvailable = (
		query: {
			chassisSeries: string;
			/** @format int32 */
			chassisNumber: number;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/service-activities-chassis`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Response will retunr information if service content is available for part number.
	 *
	 * @tags ServiceProtocol
	 * @name IsServiceContentAvailableForProductNumber
	 * @summary Get service content
	 * @request GET:/v1/service-protocol/service-activities-part
	 * @secure
	 */
	isServiceContentAvailableForProductNumber = (
		query: {
			productDesignation: string;
			productNumber: string;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/service-activities-part`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Response will retunr information if service content is available for product designation.
	 *
	 * @tags ServiceProtocol
	 * @name IsServiceContentAvailableForProductDesignation
	 * @summary Get service content
	 * @request GET:/v1/service-protocol/service-activities-designation
	 * @secure
	 */
	isServiceContentAvailableForProductDesignation = (
		query: {
			productDesignation: string;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/service-activities-designation`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name SaveServiceProtocol
	 * @summary Saves and finalizes offline service protocol. Cancels also previous initiated protocols if any.
	 * @request POST:/v1/service-protocol
	 * @secure
	 */
	saveServiceProtocol = (
		data: FinalizeOfflineServiceProtocolCommand,
		params: RequestParams = {}
	) =>
		this.request<number, ValidationErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name SaveServiceProtocolDraft
	 * @summary Saves online service protocol draft. Will set status as initiated.
	 * @request POST:/v1/service-protocol/draft
	 * @secure
	 */
	saveServiceProtocolDraft = (
		data: SaveOnlineServiceProtocolDraftCommand,
		params: RequestParams = {}
	) =>
		this.request<number, ValidationErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/draft`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Response will contain data object with dates which require backend check/calculation.
	 *
	 * @tags ServiceProtocol
	 * @name GetServiceProtocolInitialDates
	 * @summary Get dates for protocol generation
	 * @request GET:/v1/service-protocol/initial-dates
	 * @secure
	 */
	getServiceProtocolInitialDates = (
		query: {
			/** @format int64 */
			drivelineId: number;
		},
		params: RequestParams = {}
	) =>
		this.request<
			GetServiceProtocolInitialDatesDto,
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/service-protocol/initial-dates`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name FinalizeServiceProtocol
	 * @summary Saves and finalizes online service protocol. Cancels also previous initiated protocols if any.
	 * @request POST:/v1/service-protocol/{id}/finalize
	 * @secure
	 */
	finalizeServiceProtocol = (
		id: number,
		data: FinalizeOnlineServiceProtocolCommand,
		params: RequestParams = {}
	) =>
		this.request<number, ValidationErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/${id}/finalize`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags ServiceProtocol
	 * @name GetSupportedProtocolLanguageList
	 * @summary Get supported language list for service protocol content
	 * @request GET:/v1/service-protocol/language-list-protocol
	 * @secure
	 */
	getSupportedProtocolLanguageList = (params: RequestParams = {}) =>
		this.request<Record<string, string>, void | ErrorResponse>({
			path: `/v1/service-protocol/language-list-protocol`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Post method is needed to attach authentication token to a file request
	 *
	 * @tags ServiceProtocol
	 * @name GetServiceFileContent
	 * @summary Get service file content
	 * @request POST:/v1/service-protocol/service-file-content
	 * @secure
	 */
	getServiceFileContent = (
		query: {
			/** @format int32 */
			fileId: number;
		},
		params: RequestParams = {}
	) =>
		this.request<void, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/service-protocol/service-file-content`,
			method: 'POST',
			query: query,
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags SoftwareKit
	 * @name GetKits
	 * @summary Get software kits applicable to requested unit.
	 * @request GET:/v1/software-kit/kits
	 * @secure
	 */
	getKits = (
		query: {
			/** @format int32 */
			unitId: number;
		},
		params: RequestParams = {}
	) =>
		this.request<Kit[], void | ErrorResponse>({
			path: `/v1/software-kit/kits`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetUnitInfoById
	 * @summary Returns unit information.
	 * @request GET:/v1/unit/{id}/info
	 * @secure
	 */
	getUnitInfoById = (id: number, params: RequestParams = {}) =>
		this.request<UnitInfoDto, void | NotFoundErrorResponse | ErrorResponse>(
			{
				path: `/v1/unit/${id}/info`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}
		);
	/**
	 * @description Measurement part is only viable for marine units.
	 *
	 * @tags Unit
	 * @name UpdateUnitInfo
	 * @summary Updates information about the unit.
	 * @request POST:/v1/unit/{id}/info
	 * @secure
	 */
	updateUnitInfo = (
		id: number,
		data: UnitInfoDtoBase,
		params: RequestParams = {}
	) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${id}/info`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description Filtering is available.
	 *
	 * @tags Unit
	 * @name GetConnectedUnits
	 * @summary Returns list of units, which are connected to the specified consumer/end customer.
	 * @request GET:/v1/unit/connected-units
	 * @secure
	 */
	getConnectedUnits = (
		query: {
			/** @format int32 */
			consumerId: number;
			mainSegment?: MainSegment | null;
			name?: string | null;
			status?: string | null;
			brand?: string | null;
			model?: string | null;
			hullId?: string | null;
			mmsi?: string | null;
			imo?: string | null;
			callsign?: string | null;
			machineId?: string | null;
		},
		params: RequestParams = {}
	) =>
		this.request<ConnectedUnit[], void | ErrorResponse>({
			path: `/v1/unit/connected-units`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Includes: * Product individual comments * Product individual campaign info
	 *
	 * @tags Unit
	 * @name GetUnitStructureById
	 * @summary Returns unit information with whole unit structure.
	 * @request GET:/v1/unit/{id}/structure
	 * @secure
	 */
	getUnitStructureById = (id: number, params: RequestParams = {}) =>
		this.request<
			UnitStructureDto,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${id}/structure`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetUnitEvcStatus
	 * @summary Returns unit EVC status
	 * @request GET:/v1/unit/{id}/evc-status
	 * @secure
	 */
	getUnitEvcStatus = (id: number, params: RequestParams = {}) =>
		this.request<
			EvcUnitStatusResponse,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${id}/evc-status`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name RegisterUnit
	 * @summary Register new unit.
	 * @request POST:/v1/unit/register
	 * @secure
	 */
	registerUnit = (data: UnitRegisterCommand, params: RequestParams = {}) =>
		this.request<number, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/unit/register`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Possible to add or edit installations, drivelines and product individuals.
	 *
	 * @tags Unit
	 * @name EditUnitConfiguration
	 * @summary Update unit configuration.
	 * @request POST:/v1/unit/configuration
	 * @secure
	 */
	editUnitConfiguration = (
		data: EditUnitConfigurationCommand,
		params: RequestParams = {}
	) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/configuration`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name CreateOrUpdateUnitLocation
	 * @summary Update unit current location.
	 * @request POST:/v1/unit/location
	 * @secure
	 */
	createOrUpdateUnitLocation = (
		data: CreateOrUpdateUnitLocationCommand,
		params: RequestParams = {}
	) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/location`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description Response is strictly tailored for the frontend app.
	 *
	 * @tags Unit
	 * @name CompareVariants
	 * @summary Get list of variants for drivelines inside the unit.
	 * @request GET:/v1/unit/{unitId}/variants
	 * @secure
	 */
	compareVariants = (unitId: number, params: RequestParams = {}) =>
		this.request<
			VariantComparison,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/variants`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetOtherPartsForUnit
	 * @summary Retrieves unit parts info, ordered and installed in unit.
	 * @request GET:/v1/unit/{unitId}/parts
	 * @secure
	 */
	getOtherPartsForUnit = (unitId: number, params: RequestParams = {}) =>
		this.request<
			UnitOtherPartsDto,
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/parts`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name UpdateOtherPartsForUnit
	 * @summary Updates list of unit parts.
	 * @request POST:/v1/unit/parts
	 * @secure
	 */
	updateOtherPartsForUnit = (
		data: UpdateOtherPartsForUnitCommand,
		params: RequestParams = {}
	) =>
		this.request<void, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/unit/parts`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description Files should be already uploaded using <a href="/v1/file/upload-temporary-file">/v1/file/upload-temporary-file</a>
	 *
	 * @tags Unit
	 * @name AdditionalFiles
	 * @summary Attach additional files to unit.
	 * @request POST:/v1/unit/additional-files
	 * @secure
	 */
	additionalFiles = (
		data: CreateAdditionalFilesCommand,
		params: RequestParams = {}
	) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/additional-files`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetUnitOwnerAndManager
	 * @summary Retrieve end customer related to the unit.
	 * @request GET:/v1/unit/{unitId}/end-customers
	 * @secure
	 */
	getUnitOwnerAndManager = (unitId: number, params: RequestParams = {}) =>
		this.request<OwnerAndUnitManagerDto, void | ErrorResponse>({
			path: `/v1/unit/${unitId}/end-customers`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name CreateConsumersAndAssignToUnit
	 * @summary Connects owner and operator to unit, unit location is also required.
	 * @request POST:/v1/unit/{unitId}/end-customers
	 * @secure
	 */
	createConsumersAndAssignToUnit = (
		unitId: number,
		data: CreateAndConnectConsumersToUnitCommand,
		params: RequestParams = {}
	) =>
		this.request<void, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/unit/${unitId}/end-customers`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetProductInOperationData
	 * @summary Retrieves data for process - product in operation.
	 * @request GET:/v1/unit/{unitId}/product-in-operation
	 * @secure
	 */
	getProductInOperationData = (unitId: number, params: RequestParams = {}) =>
		this.request<ProductInOperationDto, void | ErrorResponse>({
			path: `/v1/unit/${unitId}/product-in-operation`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name PerformProductInOperation
	 * @summary Perform process product in operation for a unit.
	 * @request POST:/v1/unit/{unitId}/product-in-operation
	 * @secure
	 */
	performProductInOperation = (
		unitId: number,
		data: CreateUpdateProductInOperationCommand,
		params: RequestParams = {}
	) =>
		this.request<
			ProductInOperationResponse,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/product-in-operation`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name CommissioningData
	 * @summary Retrieves data for process - commissioning.
	 * @request GET:/v1/unit/{unitId}/commissioning
	 * @secure
	 */
	commissioningData = (unitId: number, params: RequestParams = {}) =>
		this.request<CommissioningDto, void | ErrorResponse>({
			path: `/v1/unit/${unitId}/commissioning`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name PerformCommissioning
	 * @summary Perform commissioning process for a unit.
	 * @request POST:/v1/unit/{unitId}/commissioning
	 * @secure
	 */
	performCommissioning = (
		unitId: number,
		data: CreateOrUpdateCommissioningCommand,
		params: RequestParams = {}
	) =>
		this.request<
			boolean,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/commissioning`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetActiveServiceAgreement
	 * @summary Check if unit has any active service agreement.
	 * @request GET:/v1/unit/{unitId}/active-service-agreement
	 * @secure
	 */
	getActiveServiceAgreement = (unitId: number, params: RequestParams = {}) =>
		this.request<ServiceAgreementDto, void | ErrorResponse>({
			path: `/v1/unit/${unitId}/active-service-agreement`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description * Return campaigns filtered for dealer or internal user * Additionally returns link to campaign publication, but only the VPPC one.
	 *
	 * @tags Unit
	 * @name GetUnitCampaignsWithoutPolicy
	 * @summary Return campaigns, except for policy type, for product individuals in the unit.
	 * @request GET:/v1/unit/{unitId}/campaigns
	 * @secure
	 */
	getUnitCampaignsWithoutPolicy = (
		unitId: number,
		query?: {
			/** If result set should contain concerned individuals with campaigns performed */
			includePerformed?: boolean;
			/** If result set should contain closed campaigns */
			includeClosed?: boolean;
			/** Get campaigns for internal roles or for external roles */
			queryAsExternal?: boolean;
		},
		params: RequestParams = {}
	) =>
		this.request<ConcernedIndividualCampaignDto[], void | ErrorResponse>({
			path: `/v1/unit/${unitId}/campaigns`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description At the moment accepts only one image, as profile picture. Previous one will be replaced.
	 *
	 * @tags Unit
	 * @name UploadImage
	 * @summary Add jpeg image to unit.
	 * @request POST:/v1/unit/{unitId}/images
	 * @secure
	 */
	uploadImage = (
		unitId: number,
		data: {
			/** @format binary */
			file?: File;
		},
		params: RequestParams = {}
	) =>
		this.request<
			number,
			ValidationFailure[] | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/images`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name DeleteImage
	 * @summary Delete image for the specified unit.
	 * @request DELETE:/v1/unit/{unitId}/images
	 * @secure
	 */
	deleteImage = (
		unitId: number,
		query?: {
			/**
			 * Image id
			 * @format int32
			 */
			imageId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<number, void | ErrorResponse>({
			path: `/v1/unit/${unitId}/images`,
			method: 'DELETE',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name ReplaceIndividual
	 * @summary Replace product individual in the unit.
	 * @request POST:/v1/unit/{id}/replace-individual
	 * @secure
	 */
	replaceIndividual = (
		id: number,
		data: ReplaceProductIndividualCommand,
		params: RequestParams = {}
	) =>
		this.request<
			boolean,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${id}/replace-individual`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name SwapIndividual
	 * @summary Swap product individual with another one.
	 * @request POST:/v1/unit/{id}/swap-individual
	 * @secure
	 */
	swapIndividual = (
		id: number,
		data: SwapIndividualInDrivelinesCommand,
		params: RequestParams = {}
	) =>
		this.request<
			boolean,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${id}/swap-individual`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Only available for external customer/dealer.
	 *
	 * @tags Unit
	 * @name SetUnitAsFavorite
	 * @summary Add unit to customer/dealer favorites list.
	 * @request POST:/v1/unit/favorites/create
	 * @secure
	 */
	setUnitAsFavorite = (
		data: SetUnitAsFavoriteCommand,
		params: RequestParams = {}
	) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/favorites/create`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description Only available for external customer/dealer.
	 *
	 * @tags Unit
	 * @name UnsetUnitAsFavorite
	 * @summary Can be used to unset unit as favorite for customer/dealer.
	 * @request DELETE:/v1/unit/favorites/delete
	 * @secure
	 */
	unsetUnitAsFavorite = (
		query: {
			/** @format int64 */
			unitId: number;
		},
		params: RequestParams = {}
	) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/favorites/delete`,
			method: 'DELETE',
			query: query,
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name ForceUnitSync
	 * @summary Run synchronization job for unit.
	 * @request POST:/v1/unit/{unitId}/force-sync
	 * @secure
	 */
	forceUnitSync = (unitId: number, params: RequestParams = {}) =>
		this.request<void, void | NotFoundErrorResponse | ErrorResponse>({
			path: `/v1/unit/${unitId}/force-sync`,
			method: 'POST',
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name DeleteUnit
	 * @summary Delete unit
	 * @request DELETE:/v1/unit/{unitId}
	 * @secure
	 */
	deleteUnit = (unitId: number, params: RequestParams = {}) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}`,
			method: 'DELETE',
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetCoveragesForUnit
	 * @summary Retrieves warranty coverage data for all the drivelines in the unit.
	 * @request GET:/v1/unit/{unitId}/coverages
	 * @secure
	 */
	getCoveragesForUnit = (unitId: number, params: RequestParams = {}) =>
		this.request<
			DrivelineCoveragesDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/coverages`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name GetEventLogsForUnit
	 * @summary Retrieves eventlog data for all levels in the unit (individual/driveline/unit).
	 * @request GET:/v1/unit/{unitId}/eventlogs
	 * @secure
	 */
	getEventLogsForUnit = (unitId: number, params: RequestParams = {}) =>
		this.request<
			EventLogDto[],
			void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/eventlogs`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Unit
	 * @name UpdateEvcDriveline
	 * @summary Updates EVC driveline based on VDA parameters, only possible if EVC status equals Updated.
	 * @request POST:/v1/unit/{unitId}/update-evc-driveline
	 * @secure
	 */
	updateEvcDriveline = (unitId: number, params: RequestParams = {}) =>
		this.request<
			void,
			DomainErrorResponse | void | NotFoundErrorResponse | ErrorResponse
		>({
			path: `/v1/unit/${unitId}/update-evc-driveline`,
			method: 'POST',
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name CheckIfUnitIsEmob
	 * @summary Performs check if unit is of an electric mobility type.
	 * @request GET:/v1/vda/emob-unit
	 * @secure
	 */
	checkIfUnitIsEmob = (
		query?: {
			/**
			 * Unit id
			 * @format int64
			 */
			unitId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, string | void | ErrorResponse>({
			path: `/v1/vda/emob-unit`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name CheckIfUnitHasTelematics
	 * @summary Performs check if unit has telematics.
	 * @request GET:/v1/vda/telematics-unit
	 * @secure
	 */
	checkIfUnitHasTelematics = (
		query?: {
			/**
			 * Unit id
			 * @format int64
			 */
			unitId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, string | void | ErrorResponse>({
			path: `/v1/vda/telematics-unit`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name GetComponentsAndSoftware
	 * @summary Retrieves first level of the components and software parameters for a chassis.
	 * @request GET:/v1/vda/components-and-software
	 * @secure
	 */
	getComponentsAndSoftware = (
		query?: {
			/** Chassis identification series */
			chassisSeries?: string;
			/**
			 * Chassis identification number
			 * @format int32
			 */
			chassisNumber?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<ComponentAndSoftwareDto[], void | ErrorResponse>({
			path: `/v1/vda/components-and-software`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name GetComponentsAndSoftwareChildren
	 * @summary Returns list of components and software parameters that are children to a node.
	 * @request GET:/v1/vda/components-and-software-children
	 * @secure
	 */
	getComponentsAndSoftwareChildren = (
		query?: {
			/**
			 * Node id
			 * @format int32
			 */
			nodeId?: number;
			/** Node type */
			nodeType?: NodeType;
		},
		params: RequestParams = {}
	) =>
		this.request<ComponentAndSoftwareDto[], void | ErrorResponse>({
			path: `/v1/vda/components-and-software-children`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description Exported file contains first level and all the children nodes.
	 *
	 * @tags Vda
	 * @name GetComponentsAndSoftwareCsvExport
	 * @summary Returns components and software parameters for a chassis exported to CSV file.
	 * @request POST:/v1/vda/components-and-software-csv
	 * @secure
	 */
	getComponentsAndSoftwareCsvExport = (
		query?: {
			/** Chassis identification series */
			chassisSeries?: string;
			/**
			 * Chassis identification number
			 * @format int32
			 */
			chassisNumber?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<void, void | ErrorResponse>({
			path: `/v1/vda/components-and-software-csv`,
			method: 'POST',
			query: query,
			secure: true,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name CheckIfDrivelineIsEvc2
	 * @summary Checks if the driveline/chassis is of type EVC 2.0.
	 * @request GET:/v1/vda/evc2-driveline
	 * @secure
	 */
	checkIfDrivelineIsEvc2 = (
		query?: {
			/** Chassis identification series */
			chassisSeries?: string;
			/**
			 * Chassis identification number
			 * @format int32
			 */
			chassisNumber?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, void | ErrorResponse>({
			path: `/v1/vda/evc2-driveline`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name GetDrivelineControlSystemInfo
	 * @summary Retrieves information about control system inside the requested chassis.
	 * @request GET:/v1/vda/driveline-control-system-info
	 * @secure
	 */
	getDrivelineControlSystemInfo = (
		query?: {
			/** Chassis identification series */
			chassisSeries?: string;
			/**
			 * Chassis identification number
			 * @format int32
			 */
			chassisNumber?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<DrivelineControlSystemInfo, void | ErrorResponse>({
			path: `/v1/vda/driveline-control-system-info`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name IsVmmResettable
	 * @summary Performs check if the VMM reset can be performed on the driveline/chassis.
	 * @request GET:/v1/vda/vmm-resetable
	 * @secure
	 */
	isVmmResettable = (
		query?: {
			/**
			 * Driveline id
			 * @format int64
			 */
			drivelineId?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<boolean, void | ErrorResponse>({
			path: `/v1/vda/vmm-resetable`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name PerformVmmReset
	 * @summary Performs VMM reset on the requested driveline/chassis.
	 * @request POST:/v1/vda/vmm-reset
	 * @secure
	 */
	performVmmReset = (data: ResetVmmCommand, params: RequestParams = {}) =>
		this.request<boolean, DomainErrorResponse | void | ErrorResponse>({
			path: `/v1/vda/vmm-reset`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags Vda
	 * @name GetSoftwareParameters
	 * @summary Retrieves TEA, TEA2, TEA2Plus software parameters for a chassis.
	 * @request GET:/v1/vda/software-parameters
	 * @secure
	 */
	getSoftwareParameters = (
		query?: {
			/** Chassis identification series */
			chassisSeries?: string;
			/**
			 * Chassis identification number
			 * @format int32
			 */
			chassisNumber?: number;
		},
		params: RequestParams = {}
	) =>
		this.request<
			SoftwareParameterDto[],
			DomainErrorResponse | void | ErrorResponse
		>({
			path: `/v1/vda/software-parameters`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
}
