import { url_vppn_get_token, url_vppn_refresh_token } from 'api/ApiUrls';

const VPPN_AUTH_TOKEN_HEADER_NAME =
	(import.meta.env.VITE_VPPN_AUTH_SERVER_TOKEN_HEADER_NAME as string) ||
	'X-Vppn-Auth-Token';
const VPPN_AUTH_SERVER_USER_HEADER_NAME =
	(import.meta.env.VITE_VPPN_AUTH_SERVER_USER_HEADER_NAME as string) ||
	'SM_USER';

const getToken = (url: string, headers?: Record<string, string>) =>
	new Promise<string | null>((resolve, reject) => {
		const req = new XMLHttpRequest();
		req.onload = () => {
			if (req.status >= 200 && req.status < 300) {
				resolve(
					req.getResponseHeader(
						VPPN_AUTH_TOKEN_HEADER_NAME.toLowerCase()
					)
				);
			} else {
				reject(
					new Error(
						`Token request rejected with status ${req.status} ${req.statusText}`
					)
				);
			}
		};
		req.onerror = () => {
			reject(new Error('Token request failed.'));
		};
		req.open('GET', url);
		if (headers) {
			Object.keys(headers).forEach((key) => {
				req.setRequestHeader(key, headers[key]);
			});
		}
		req.send();
	});

export const getVppnToken = () => getToken(url_vppn_get_token);

export const refreshVppnToken = (userId: string) =>
	getToken(url_vppn_refresh_token, {
		[VPPN_AUTH_SERVER_USER_HEADER_NAME]: userId,
	});
