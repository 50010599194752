import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ApplicationApi, MainSegment } from 'api';
import { cacheTime } from 'api/ReactQueryOptions';
import { UnitApi } from 'api/UnitApi';
import { IBaseResponse } from 'api/axios/responses/BaseResponse';
import { V2 } from 'api/v2/V2';

const unitApi = new UnitApi();

export const unitKeys = {
	all: ['unit'] as const,
	connectedUnits: (customerId: number, filters: object) =>
		[...unitKeys.all, 'connected-units', customerId, filters] as const,

	single: (unitId: number) => [...unitKeys.all, unitId] as const,
	structure: (unitId: number) =>
		[...unitKeys.single(unitId), 'structure'] as const,
	structureV2: (unitId: number) =>
		[...unitKeys.single(unitId), 'structureV2'] as const,
	info: (unitId: number) => [...unitKeys.single(unitId), 'info'] as const,
	variants: (unitId: number) =>
		[...unitKeys.single(unitId), 'variants'] as const,
	evcStatus: (unitId: number) =>
		[...unitKeys.single(unitId), 'evc-status'] as const,
	demo: (unitId: number) => [...unitKeys.single(unitId), 'demo'] as const,
	parts: (unitId: number) => [...unitKeys.single(unitId), 'parts'] as const,
	connectedCustomers: (unitId: number) =>
		[...unitKeys.single(unitId), 'connected-customers'] as const,
	productInOperationData: (unitId: number) =>
		[...unitKeys.single(unitId), 'product-in-operation'] as const,
	commissioningData: (unitId: number) =>
		[...unitKeys.single(unitId), 'commissioning'] as const,
	activeServiceAgreementData: (unitId: number) =>
		[...unitKeys.single(unitId), 'active-service-agreement'] as const,
	campaigns: (
		unitId: number,
		includePerformed: boolean,
		includeClosed: boolean,
		queryAsExternal: boolean
	) =>
		[
			...unitKeys.single(unitId),
			'campaigns',
			includePerformed,
			includeClosed,
			queryAsExternal,
		] as const,
	coverages: (unitId: number) =>
		[...unitKeys.single(unitId), 'coverages'] as const,
	telematic: (unitId: number) =>
		[...unitKeys.single(unitId), 'telematic'] as const,
	eventLog: (unitId: number) =>
		[...unitKeys.single(unitId), 'event-log'] as const,
};

export const unitStructureQuery = (unitId: number) => ({
	queryKey: unitKeys.structure(unitId),
	queryFn: async () => (await unitApi.getUnitStructure(unitId)).data,
	...cacheTime(1),
});

export const unitStructureV2Query = (api: V2, unitId: number) => ({
	queryKey: unitKeys.structure(unitId),
	queryFn: async () => (await api.getUnitStructureById(unitId)).data,
	//...cacheTime(1),
});

export const unitInfoQuery = (api: ApplicationApi, unitId: number) => ({
	queryKey: unitKeys.info(unitId),
	queryFn: async () => (await api.getUnitInfoById(unitId)).data,
	...cacheTime(1),
});

export const unitOtherPartsQuery = (api: ApplicationApi, unitId: number) => ({
	queryKey: unitKeys.parts(unitId),
	queryFn: async () => (await api.getOtherPartsForUnit(unitId)).data,
	...cacheTime(5),
});

const unitBaseQuery =
	<TQueryKeyReturn, TReturn>(
		queryFn: (id: number) => TQueryKeyReturn,
		apiCall: (
			api: ApplicationApi,
			id: number
		) => Promise<IBaseResponse<TReturn> | AxiosResponse<TReturn>>
	) =>
	(api: ApplicationApi, unitId: number) => ({
		queryKey: queryFn(unitId),
		queryFn: async () => {
			const { data, status } = await apiCall(api, unitId);
			return status === 204 ? null : data;
		},
	});

export const unitEvcStatusQuery = (api: ApplicationApi, unitId: number) => ({
	queryKey: unitKeys.evcStatus(unitId),
	queryFn: async () => (await api.getUnitEvcStatus(unitId)).data,
	...cacheTime(1),
});

export const unitVariantsQuery = unitBaseQuery(unitKeys.variants, (api, id) =>
	api.compareVariants(id)
);

export const unitConnectedCustomersQuery = (
	api: ApplicationApi,
	id: number
) => ({
	queryKey: unitKeys.connectedCustomers(id),
	queryFn: async () => (await api.getUnitOwnerAndManager(id)).data,
});

export const unitProductInOperationDataQuery = unitBaseQuery(
	unitKeys.productInOperationData,
	(api, id) => api.getProductInOperationData(id)
);

export const unitCommissioningDataQuery = unitBaseQuery(
	unitKeys.commissioningData,
	(api, id) => api.commissioningData(id)
);

export const unitActiveServiceAgreementDataQuery = unitBaseQuery(
	unitKeys.activeServiceAgreementData,
	(api, id) => api.getActiveServiceAgreement(id)
);

export const unitCoveragesQuery = unitBaseQuery(unitKeys.coverages, (api, id) =>
	api.getCoveragesForUnit(id)
);

export const unitEventLogQuery = (api: ApplicationApi, id: number) => ({
	queryKey: unitKeys.eventLog(id),
	queryFn: async () => (await api.getEventLogsForUnit(id)).data,
});

export const unitCampaignsQuery = (
	api: ApplicationApi,
	id: number,
	includePerformed: boolean,
	includeClosed: boolean,
	queryAsExternal: boolean
) => ({
	queryKey: unitKeys.campaigns(
		id,
		includePerformed,
		includeClosed,
		queryAsExternal
	),
	queryFn: async () => {
		const { data, status } = await api.getUnitCampaignsWithoutPolicy(id, {
			includePerformed,
			includeClosed,
			queryAsExternal,
		});
		return status === 204 ? null : data;
	},
});

export const unitIsTelematicQuery = (api: ApplicationApi, unitId: number) => ({
	queryKey: unitKeys.telematic(unitId),
	queryFn: async () => (await api.checkIfUnitHasTelematics({ unitId })).data,
});

export type QueryConnectedUnitsFilters = {
	mainSegment?: MainSegment | null;
	name?: string | null;
	status?: string | null;
	brand?: string | null;
	model?: string | null;
	hullId?: string | null;
	mmsi?: string | null;
	imo?: string | null;
	callsign?: string | null;
	machineId?: string | null;
};

export const useQueryConnectedUnits = (
	api: ApplicationApi,
	customerId: number | null | undefined,
	queryFilters: QueryConnectedUnitsFilters
) => {
	const { t } = useTranslation(['common']);
	const { enqueueSnackbar } = useSnackbar();

	const query = useQuery({
		queryKey: unitKeys.connectedUnits(customerId ?? 0, queryFilters),
		queryFn: async () => {
			const response = await api.getConnectedUnits({
				consumerId: customerId as number,
				...queryFilters,
			});
			return response.data;
		},
		enabled: customerId != null && customerId !== 0,
	});

	useEffect(() => {
		if (query.error) {
			enqueueSnackbar(t('error-unexpected'), {
				variant: 'error',
			});
		}
	}, [t, enqueueSnackbar, query.error]);

	return query;
};
