import { Box, BoxProps } from '@mui/material';

interface PageProps {
	children?: React.ReactNode;
}

type BodyProps = PageProps & BoxProps;

const Body: React.FC<BodyProps> = ({
	children,
	...props
}: BodyProps): JSX.Element => {
	return (
		<Box component="main" id="body-container" {...props}>
			{children}
		</Box>
	);
};

export default Body;
