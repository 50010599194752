/* eslint-disable @typescript-eslint/no-explicit-any */
import { InternalAxiosRequestConfig } from 'axios';

import { AuthProviderStorage } from 'main/auth/provider/Storage';

// function checks if authentication refresh process is ongoing and stop executing request until refresh's completion or timeout
// in case of a new token it's being attached to request
export const authRequestInterceptor = async ({
	headers,
	url,
	...rest
}: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> => {
	const provider = AuthProviderStorage.getInstance().getProvider();
	const accessToken = await provider.getTokenAsync();

	if (!accessToken) {
		throw new Error('Access token is empty.');
	}

	headers.Authorization = `Bearer ${accessToken}`;

	// if there is a new token it need to be replaced
	return {
		url,
		headers,
		...rest,
	};
};
