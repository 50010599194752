import { NonIndexRouteObject } from 'react-router-dom';

import { getVppnToken } from 'api/axios/vppn';
import { errors_AuthenticationUrl } from 'main/routes/urls/RouteUrls';

import { EmptyProvider } from './Empty';
import { MsalProvider } from './Msal';
import { AuthenticationProvider } from './Provider';
import { AuthProviderStorage } from './Storage';
import { VppnProvider } from './Vppn';

const defaultProvider = new EmptyProvider();

const initializeAuthProviderStorage: NonIndexRouteObject['loader'] = ({
	request,
}) =>
	new Promise((resolve) => {
		const url = new URL(request.url);

		// if current page is authentication error, then there is no point in initializing any authentication provider
		if (url.pathname === errors_AuthenticationUrl) {
			resolve(null);
		}

		getVppnToken()
			.then((token) => {
				AuthProviderStorage.initialize(
					token ? new VppnProvider(token) : new MsalProvider()
				);
			})
			.catch(() => {
				AuthProviderStorage.initialize(defaultProvider);
			})
			.finally(() => {
				resolve(AuthProviderStorage.getInstance().getProvider());
			});
	});

const isVppn = (): boolean =>
	AuthProviderStorage.getInstance().getProvider() instanceof VppnProvider;

export {
	initializeAuthProviderStorage,
	isVppn,
	AuthenticationProvider,
	AuthProviderStorage,
	EmptyProvider,
	MsalProvider,
	VppnProvider,
};
