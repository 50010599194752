import { Close, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, debounce } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { SearchProductTextField } from './SearchProductTextField';

type SearchFormValues = {
	phrase: string;
};

export type UseSearchParamType = {
	searchParamName?: string;
};

export const useSearchParam = ({
	searchParamName = 'by',
}: UseSearchParamType) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const inputValue = searchParams.get(searchParamName);

	return {
		value: inputValue,
		change: (input: string) => {
			setSearchParams((prev) => {
				if (input.length > 0 && prev.get(searchParamName) !== input) {
					prev.set(searchParamName, input);
				}

				return prev;
			});
		},
		clear: () => {
			setSearchParams((params) => {
				params.set(searchParamName, '');
				return params;
			});
		},
	};
};

export interface SearchProductFormParams {
	defaultInput?: string;
	onInputChange: (data: string) => void;
	onInputClear?: () => void;
}

export const SearchProductForm = ({
	defaultInput = '',
	onInputChange,
	onInputClear,
}: SearchProductFormParams) => {
	const { t } = useTranslation(['searchProduct']);

	const { register, handleSubmit, watch, setValue } =
		useForm<SearchFormValues>({
			mode: 'onChange',
			defaultValues: {
				phrase: defaultInput,
			},
		});

	const submitHandler: SubmitHandler<SearchFormValues> = (data) => {
		if (data.phrase.length > 2) {
			onInputChange(data.phrase);
		}
	};

	const handleClear = () => {
		setValue('phrase', '');
		if (typeof onInputClear === 'function') {
			onInputClear();
		}
	};

	const searchValue = watch('phrase');

	const debouncedSubmit = debounce(handleSubmit(submitHandler), 1500);

	return (
		<form
			onSubmit={handleSubmit(submitHandler)}
			onReset={handleClear}
			onChange={debouncedSubmit}>
			<SearchProductTextField
				id="search-product-input"
				label={t('search-input-helper-text')}
				variant="standard"
				slotProps={{
					inputLabel:
						searchValue.length == 0 ? { shrink: false } : undefined,
					input: {
						startAdornment: (
							<InputAdornment position="start">
								<Search color="primary" />
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position="end">
								<IconButton type="reset">
									<Close color="primary" />
								</IconButton>
							</InputAdornment>
						),
					},
				}}
				{...register('phrase')}
				sx={{
					width: { xs: 1, sm: 550 },
				}}
			/>
		</form>
	);
};
