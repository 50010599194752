import { withAITracking } from '@microsoft/applicationinsights-react-js';
import {
	Box,
	IconButton,
	LinearProgress,
	Theme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { GridColDef, GridRowIdGetter, GridSlots } from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import {
	MainSegment,
	SearchProductIndividualDto,
	useProductCenterApi,
} from 'api';
import { searchProductQuery } from 'api/queries';
import { getChassis } from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';
import {
	formatOrderName,
	pentaProductIndividualCategories,
	productIndividualStatusToTranslationMapping,
	productIndividualTypeTranslation,
} from 'domain/productIndividual';
import { unitStatusToTranslationMapping } from 'domain/unit';
import {
	DataGridToolbar,
	LocalizedDataGrid,
	PageContent,
} from 'library/components/controls';
import {
	SearchProductForm,
	useSearchParam,
} from 'library/components/controls/search/SearchForm/SearchProductForm';
import { EmailLink, Link } from 'library/components/link';
import { getLocalizedDateFormat } from 'library/formatters';
import Variables from 'library/utils/themes/Variables';
import { reactPlugin } from 'main/azureInsights/config';
import TitleWrapper from 'main/layout/TitleWrapper';
import { orderInfoPageUrl } from 'main/routes/urls/RouteUrls';
import { getFilterOperators } from 'modules/EndCustomer/Shared/DataGrid/CellRender/CountryCell';
import { ServiceButton } from 'modules/MaintenancePlanner/MaintenanceDataGrid/Components/ServiceButton';
import {
	PartShopLink,
	ProductIndividualLink,
	PublicationsLink,
} from 'modules/ProductIndividualview/Components';
import { RegisterProductButton } from 'modules/ProductIndividualview/Components/RegisterProductButton';
import { AssignTemporaryDrivelineButton } from 'modules/SearchProduct/AssignTemporaryDrivelineButton';
import { UnitCell } from 'modules/SearchProduct/UnitCell';
import { CampaignBadge } from 'modules/Shared/Components/ConnectedUnits/CampaignBadge';
import { unitSegmentToTranslationMapping } from 'modules/Shared/Components/ConnectedUnits/SegmentCellComponent';
import { LocationText } from 'modules/Shared/Components/OperatingLocation/LocationText';
import { DrivelineLink } from 'modules/UnitCreation/SharedComponents/Driveline/DrivelineTitleLink';

const linkColor = {
	color: 'primary.main',
};

const SearchProductPage = (): JSX.Element => {
	const { t } = useTranslation([
		'commonUnit',
		'commonStatus',
		'searchProduct',
		'common',
		'orderInfo',
		'serviceProtocol',
		'maintenancePlanner',
		'productIndividual',
		'productType',
	]);

	const theme = useTheme<Variables>();
	const isMobile = useMediaQuery((theme: Theme) =>
		theme.breakpoints.only('xs')
	);

	const { api } = useProductCenterApi();

	const { value: searchPhrase, change, clear } = useSearchParam({});

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const { data, isLoading, isSuccess, isError } = useQuery({
		...searchProductQuery(api, searchPhrase as string),
		staleTime: 1000,
		retry: 0,
		enabled: !!searchPhrase,
	});

	useEffect(() => {
		let notFoundSnackbar = null;
		if (isSuccess && (data == null || data.length === 0)) {
			notFoundSnackbar = enqueueSnackbar(
				t('productIndividual:pi-not-found-error'),
				{
					variant: 'error',
				}
			);
		} else if (isError) {
			enqueueSnackbar(t('common:error-unexpected'), {
				variant: 'error',
			});
		}

		return () => {
			if (notFoundSnackbar) {
				closeSnackbar(notFoundSnackbar);
			}
		};
	}, [data, isSuccess, isError, enqueueSnackbar, t]);

	const columns2 = useMemo(
		(): GridColDef<SearchProductIndividualDto>[] => [
			{
				field: 'unitId',
				headerName: t('commonUnit:unit-title'),
				flex: 5,
				minWidth: 320,
				sortable: false,
				filterable: false,
				renderCell: ({ value, row }) => {
					if (value) {
						return (
							<UnitCell
								id={value}
								profileImageId={row.unitImageId}
								outstandingCampaignType={
									row.unitHighestCampaignType
								}
								{...row}
								linkTarget="_self"
								sx={{ p: 0.5, alignItems: 'center' }}
							/>
						);
					} else if (row.isEligibleForRegistration) {
						return (
							<RegisterProductButton
								serialNumber={row.serialNumber}
								variant="text"
							/>
						);
					}

					return null;
				},
				valueGetter: (_value, row) => row.unitId,
				rowSpanValueGetter: (value) => value,
			},
			{
				field: 'name',
				headerName: t('commonUnit:name'),
				rowSpanValueGetter: (_value, row) => row.unitId,
			},
			{
				field: 'hullId',
				headerName: t('commonUnit:hull-id'),
				rowSpanValueGetter: (_value, row) => row.unitId,
			},
			{
				field: 'hullMachineId',
				headerName: t('commonUnit:machine-id'),
				rowSpanValueGetter: (_value, row) => row.unitId,
			},
			{
				field: 'mmsiNumber',
				headerName: t('commonUnit:mmsi-number'),
				rowSpanValueGetter: (_value, row) => row.unitId,
			},
			{
				field: 'imoNumber',
				headerName: t('commonUnit:imo-number'),
				rowSpanValueGetter: (_value, row) => row.unitId,
			},
			{
				field: 'callSign',
				headerName: t('commonUnit:call-sign'),
				rowSpanValueGetter: (_value, row) => row.unitId,
			},
			{
				field: 'mainSegment',
				headerName: t('commonUnit:main-segment'),
				flex: 2,
				minWidth: 70,
				type: 'singleSelect',
				rowSpanValueGetter: rowSpanValueGetter('unitId', 'mainSegment'),
				valueOptions: () =>
					Object.keys(unitSegmentToTranslationMapping).map((id) => ({
						value: id as MainSegment,
						label: t(unitSegmentToTranslationMapping[id]),
					})),
			},
			{
				field: 'chassisId',
				headerName: t('commonUnit:chassis-id'),
				flex: 2,
				minWidth: 130,
				sortable: false,
				valueGetter: (_value, row) =>
					row.chassisSeries
						? getChassis(row.chassisSeries, row.chassisNumber)
						: null,
				renderCell: ({ value, row }) =>
					row.drivelineId ? (
						<DrivelineLink id={row.drivelineId} sx={linkColor}>
							{value}
						</DrivelineLink>
					) : (
						<AssignTemporaryDrivelineButton
							serialNumber={row.serialNumber}
						/>
					),
			},
			{
				field: 'chassisActions',
				headerName: t('searchProduct:results-chassis-actions-header'),
				renderHeader: () => null,
				type: 'actions',
				display: 'text',
				width: 60,
				rowSpanValueGetter: (_value, row) => row.drivelineId,
				renderCell: ({ row }) =>
					row.drivelineId ? (
						<ServiceButton
							id={row.drivelineId}
							ButtonElement={IconButton}
							chassisId={getChassis(
								row.chassisSeries,
								row.chassisNumber
							)}
							buttonProps={{
								color: 'secondary',
								title: t(
									'serviceProtocol:generate-wizard-title'
								),
							}}
						/>
					) : null,
			},
			{
				field: 'productCategory',
				type: 'singleSelect',
				headerName: t(
					'orderInfo:product-individuals-header-product-category'
				),
				flex: 2,
				minWidth: 100,
				display: 'flex',
				valueFormatter: productIndividualTypeTranslation(t),
				valueOptions: () =>
					pentaProductIndividualCategories.map((value) => ({
						value,
						label: productIndividualTypeTranslation(t)(value),
					})),
				rowSpanValueGetter: rowSpanValueGetter(
					'productCategory',
					'serialNumber'
				),
				renderCell: ({ value, row }) => (
					<CampaignBadge campaignType={row.highestCampaignType}>
						<Box
							title={value}
							sx={(theme) => ({ p: theme.spacing(0.2, 0.5) })}>
							{row.productCategory.slice(0, 3)}
						</Box>
					</CampaignBadge>
				),
			},
			{
				field: 'serialNumber',
				headerName: t('common:serial-number'),
				flex: 2,
				minWidth: 130,
				renderCell: ({ value }) => (
					<ProductIndividualLink serialNumber={value} sx={linkColor}>
						{value}
					</ProductIndividualLink>
				),
			},
			{
				field: 'productStatus',
				headerName: t('commonUnit:status'),
				type: 'singleSelect',
				flex: 2,
				minWidth: 120,
				valueOptions: () =>
					Object.keys(
						productIndividualStatusToTranslationMapping
					).map((value) => ({
						value,
						label: t(
							productIndividualStatusToTranslationMapping[value]
						),
					})),
				valueFormatter: (value) =>
					t(unitStatusToTranslationMapping[value]),
				rowSpanValueGetter: rowSpanValueGetter(
					'productStatus',
					'serialNumber'
				),
			},
			{
				field: 'productIndividualActions',
				headerName: t('searchProduct:results-product-actions-header'),
				renderHeader: () => null,
				type: 'actions',
				display: 'text',
				rowSpanValueGetter: (_value, row) => row.serialNumber,
				renderCell: ({ row }) => (
					<Box sx={{ '& > :first-of-type': { mr: 1 } }}>
						<PublicationsLink
							key="publicationsLink"
							serialNumber={row.serialNumber}
							isIcon
						/>
						<PartShopLink
							key="partShopLink"
							serialNumber={row.serialNumber}
							isIcon
						/>
					</Box>
				),
			},
			{
				field: 'productDesignation',
				headerName: t('common:product-designation'),
				flex: 2,
				minWidth: 140,
				rowSpanValueGetter: rowSpanValueGetter(
					'productDesignation',
					'serialNumber'
				),
			},
			{
				field: 'orderNumber',
				headerName: t('orderInfo:order-number'),
				flex: 2,
				minWidth: 130,
				valueGetter: (_value, row) =>
					formatOrderName(row.orderNumber, row.orderType),
				rowSpanValueGetter: rowSpanValueGetter(
					'orderNumber',
					'serialNumber'
				),
				renderCell: ({ value, row }) =>
					row.orderId ? (
						<Link
							to={generatePath(`/${orderInfoPageUrl}`, {
								id: row.orderId.toString(),
							})}
							sx={linkColor}>
							{value}
						</Link>
					) : null,
			},
			{
				field: 'deliveryDate',
				headerName: t('commonUnit:delivery-date'),
				flex: 2,
				minWidth: 130,
				rowSpanValueGetter: rowSpanValueGetter(
					'deliveryDate',
					'serialNumber'
				),
				valueFormatter: (value) => getLocalizedDateFormat(value),
			},
			{
				field: 'shippedToCustomerName',
				headerName: t('orderInfo:shipped-to'),
				flex: 2,
				minWidth: 150,
				rowSpanValueGetter: rowSpanValueGetter(
					'shippedToCustomerName',
					'serialNumber'
				),
			},
			{
				field: 'country',
				headerName: t('common:country'),
				type: 'singleSelect',
				flex: 2,
				minWidth: 130,
				display: 'flex',
				filterOperators: getFilterOperators,
				renderCell: ({ row }) => (
					<LocationText countryCode={row.country} />
				),
				rowSpanValueGetter: rowSpanValueGetter(
					'country',
					'serialNumber'
				),
			},
			{
				field: 'state',
				headerName: t('common:county'),
				flex: 2,
				minWidth: 130,
				rowSpanValueGetter: rowSpanValueGetter('state', 'serialNumber'),
			},
			{
				field: 'city',
				headerName: t('common:city'),
				flex: 2,
				minWidth: 130,
				rowSpanValueGetter: rowSpanValueGetter('city', 'serialNumber'),
			},
			{
				field: 'ownerEmail',
				headerName: t('maintenancePlanner:header-owner-email'),
				sortable: false,
				flex: 4,
				minWidth: 150,
				renderCell: (params) =>
					params.row.isOwnerAnonymous ? (
						params.row.ownerEmail
					) : (
						<EmailLink email={params.row.ownerEmail} />
					),
				rowSpanValueGetter: rowSpanValueGetter('ownerEmail', 'unitId'),
			},
			{
				field: 'ownerCompany',
				headerName: t('maintenancePlanner:header-company-name'),
				sortable: false,
				flex: 4,
				minWidth: 150,
				rowSpanValueGetter: rowSpanValueGetter(
					'ownerCompany',
					'unitId'
				),
			},
			{
				field: 'ownerName',
				headerName: t('maintenancePlanner:header-name-and-surname'),
				sortable: false,
				flex: 4,
				minWidth: 150,
				rowSpanValueGetter: rowSpanValueGetter('ownerName', 'unitId'),
			},
		],
		[t]
	);

	const getRowId: GridRowIdGetter<SearchProductIndividualDto> = useCallback(
		(params) => params.serialNumber,
		[]
	);

	return (
		<>
			<TitleWrapper title={t('searchProduct:search-product-header')} />
			<PageContent>
				<SearchProductForm
					defaultInput={searchPhrase ?? ''}
					onInputChange={change}
					onInputClear={clear}
				/>
				<Box
					id="search-product-data-grid"
					sx={{
						display: 'flex',
						flexDirection: 'column',
						minHeight: 400,
						maxHeight: theme.custom.dataGridHeight,
						pt: 3,
					}}>
					<LocalizedDataGrid
						slots={{
							loadingOverlay:
								LinearProgress as GridSlots['loadingOverlay'],
							toolbar: DataGridToolbar,
						}}
						slotProps={{
							loadingOverlay: {
								sx: {
									height: 3,
								},
							},
						}}
						loading={isLoading}
						rows={data ?? []}
						getRowId={getRowId}
						columns={columns2}
						unstable_rowSpanning={true}
						initialState={{
							columns: {
								columnVisibilityModel: {
									mainSegment: false,
									state: false,
									city: false,
									ownerCompany: false,
									ownerName: false,
									ownerEmail: false,
									name: false,
									hullId: false,
									hullMachineId: false,
									mmsiNumber: false,
									imoNumber: false,
									callSign: false,
								},
							},
							pinnedColumns: !isMobile
								? { left: ['unitId'] }
								: undefined,
						}}
					/>
				</Box>
			</PageContent>
		</>
	);
};

export default withAITracking(
	reactPlugin,
	SearchProductPage,
	'Search product page',
	'application-insight-container'
);

const rowSpanValueGetter =
	(
		propertyName: keyof SearchProductIndividualDto,
		secondPropertyName: keyof SearchProductIndividualDto
	) =>
	(value: never, row: SearchProductIndividualDto) =>
		row ? `${row[propertyName]}-${row[secondPropertyName]}` : value;
