import { Box, BoxProps } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

import { NoDataAvailable } from '../datagrid';
import { LoadingWrapper, LoadingWrapperProps } from './LoadingWrapper';

export interface LoadingDataWrapper<T extends GridValidRowModel> {
	loading: LoadingWrapperProps['loading'];
	isInnerGrid?: boolean;
	slotProps?: {
		loadingWrapper?: Omit<LoadingWrapperProps, 'loading' | 'children'>;
		dataGridWrapper?: BoxProps;
		noDataWrapper?: BoxProps;
	};
	data: T | null | undefined;
	noDataComponent?: ReactNode;
	render: (data: T) => ReactNode;
}

export const LoadingDataWrapper = <T extends GridValidRowModel = any>({
	loading,
	slotProps = {},
	data,
	noDataComponent,
	render,
}: LoadingDataWrapper<T>) => {
	const hasData = data && (Array.isArray(data) ? data.length > 0 : true);

	const { loadingWrapper, dataGridWrapper, noDataWrapper } = slotProps;

	return (
		<LoadingWrapper loading={loading} {...loadingWrapper}>
			{hasData ? (
				<Box {...dataGridWrapper}>{render(data)}</Box>
			) : (
				<Box {...noDataWrapper}>
					{noDataComponent != null ? (
						noDataComponent
					) : (
						<NoDataAvailable />
					)}
				</Box>
			)}
		</LoadingWrapper>
	);
};
