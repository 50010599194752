import { Box, Typography } from '@mui/material';
import {
	GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
	GridColDef,
	GridRowParams,
} from '@mui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DrivelineCoveragesDto, useProductCenterApi } from 'api';
import { unitCoveragesQuery } from 'api/queries';
import { Id } from 'domain/Id';
import {
	CustomDetailPanelToggle,
	VolvoDataGrid,
	getDetailPanelAutoHeight,
} from 'library/components/controls';
import { getLocalizedDateFormat } from 'library/formatters';
import { PCTheme } from 'library/utils/themes';

import { CoverageDataGrid } from './Coverage/CoverageDataGrid';
import { WarrantyEmpty } from './WarrantyEmpty';

const {
	palette: { grey },
} = PCTheme;

export const WarrantyDataGrid = ({ id }: Id) => {
	const { t } = useTranslation([
		'commonButton',
		'commonUnit',
		'maintenancePlanner',
		'productIndividual',
		'unitOverview',
		'warranty',
	]);

	const { api } = useProductCenterApi();
	const { data, isLoading } = useQuery(unitCoveragesQuery(api, id));

	const columns = useMemo(
		(): GridColDef<DrivelineCoveragesDto>[] => [
			{
				...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
				renderCell: ({ id, value }) => (
					<CustomDetailPanelToggle id={id} value={value} />
				),
				display: 'flex',
			},
			{
				field: 'chassisId',
				headerName: t('unitOverview:driveline-header'),
				flex: 4,
				display: 'flex',
				minWidth: 80,
				sortable: false,
			},
			{
				field: 'individuals',
				headerName: t('productIndividual:productIndividuals-header'),
				flex: 4,
				minWidth: 120,
				renderCell: ({ row: { individuals } }) => (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							gap: 1,
							margin: '0.5rem 0',
							minHeight: (theme) => theme.spacing(5),
						}}>
						{individuals.map((pi) => (
							<Typography key={pi}>{pi}</Typography>
						))}
					</Box>
				),
				sortable: false,
			},
			{
				field: 'engineDeliveryDate',
				headerName: t('commonUnit:delivery-date'),
				flex: 4,
				display: 'flex',
				type: 'date',
				minWidth: 120,
				valueFormatter: (value) => getLocalizedDateFormat(value),
				sortable: false,
			},
		],
		[t]
	);

	const getDetailPanelContent = useCallback(
		({ row }: GridRowParams<DrivelineCoveragesDto>) => (
			<DetailedPanelContent row={row} />
		),
		[]
	);

	const getRowId = useCallback(
		({ chassisId, individuals }: DrivelineCoveragesDto) =>
			`${chassisId}-${individuals.join('-')}`,
		[]
	);

	return (
		<VolvoDataGrid
			isLoading={isLoading}
			columns={columns}
			disableColumnFilter
			disableColumnMenu
			disableRowSelectionOnClick
			getDetailPanelContent={getDetailPanelContent}
			getDetailPanelHeight={getDetailPanelAutoHeight}
			getRowId={getRowId}
			getRowHeight={getDetailPanelAutoHeight}
			hideFooterRowCount
			rows={data ?? []}
			sx={{
				'& .MuiDataGrid-row.Mui-hovered': {
					backgroundColor: 'transparent',
				},
				'& .MuiDataGrid-row:hover': {
					backgroundColor: 'transparent',
				},
			}}
		/>
	);
};

const DetailedPanelContent = ({
	row: { coverageCodes },
}: {
	row: DrivelineCoveragesDto;
}) =>
	coverageCodes?.length ? (
		<CoverageDataGrid
			coverages={coverageCodes}
			density="compact"
			sx={(theme) => ({
				'& .MuiDataGrid-main': {
					padding: theme.spacing(0, 4, 2.5, 4),
				},
				'& .MuiDataGrid-columnHeaders': {
					borderTop: 0,
				},
				'& .MuiDataGrid-columnHeader': {
					backgroundColor: grey[50],
				},
			})}
		/>
	) : (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: 100,
			}}>
			<WarrantyEmpty />
		</Box>
	);
