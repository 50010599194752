import { get, isNull, isUndefined, uniq } from 'lodash';
import { FC, useMemo } from 'react';

import {
	DrivelineSubSystem,
	getChassisId,
} from 'api/responses/models/Unit/UnitStructure/DrivelineSubSystem';
import { InstallationSubSystem } from 'api/responses/models/Unit/UnitStructure/InstallationSubSystem';
import { ProductIndividualItemPurpose } from 'api/responses/models/Unit/UnitStructure/ProductIndividualItemPurpose';
import { ProductIndividualSubSystem } from 'api/responses/models/Unit/UnitStructure/ProductIndividualSubSystem';
import { isIPS40 } from 'domain/productIndividual';
import { hasDrivelinePositions } from 'domain/unit';
import { Disable, Readonly, Sxable } from 'library/types';
import { InstallationTypeCount } from 'library/utils/helpers/Unit';
import { ToggleDrivelineLocation } from 'modules/UnitCreation/SharedComponents/ToggleDrivelineLocation';
import { ReplaceIndividualDialog } from 'modules/UnitOverview/Components/ReplaceIndividualMenu/ReplaceIndividualDialog';

import DrivelineItemView, {
	ProductIndividualItemPurposeProps,
} from '../Driveline/DrivelineItemView';

const getMatchingIndividualsToSwap = (
	{
		productCategory,
		serialNumber,
	}: Pick<ProductIndividualItemPurpose, 'productCategory' | 'serialNumber'>,
	drivelines: DrivelineSubSystem[]
): string[] => {
	const matchingSerials = drivelines
		.reduce((acc: ProductIndividualSubSystem[], d) => {
			return acc.concat(d.productIndividualSubSystems);
		}, [])
		.reduce((acc: ProductIndividualItemPurpose[], ind) => {
			return acc.concat(ind.productIndividuals);
		}, [])
		.filter((ind: ProductIndividualItemPurpose) => {
			return (
				ind.serialNumber !== serialNumber &&
				ind.productCategory === productCategory
			);
		})
		.map((ind: ProductIndividualItemPurpose) => ind.serialNumber)
		.filter((s) => !isUndefined(s) && !isNull(s))
		.map((s) => s ?? '');

	return uniq(matchingSerials);
};

const unitProductIndividualCardActions =
	(
		driveline: DrivelineSubSystem,
		drivelines: DrivelineSubSystem[],
		unitId: number
	): FC<ProductIndividualItemPurposeProps> =>
	({ product: { productCategory, serialNumber } }) => (
		<ReplaceIndividualDialog
			unitId={unitId}
			chassisId={getChassisId(driveline)}
			serialNumber={serialNumber ?? ''}
			productCategory={productCategory}
			matchingIndividualsToSwap={getMatchingIndividualsToSwap(
				{
					productCategory,
					serialNumber,
				},
				drivelines
			)}
		/>
	);

interface UnitDrivelineItemView extends Readonly, Sxable, Disable {
	driveline: DrivelineSubSystem;
	installation: InstallationSubSystem;
	total: InstallationTypeCount;
	unitId: number;
	withLink?: boolean;
	isBlueprint?: boolean;
}

export const UnitDrivelineItemView = ({
	disabled = false,
	driveline,
	installation: { detailedSegment, drivelines, id, purpose },
	isReadonly = false,
	isBlueprint = false,
	total,
	sx,
	unitId,
	withLink,
}: UnitDrivelineItemView): JSX.Element => {
	const drivelinesCount =
		detailedSegment && purpose ? get(total, [purpose], 0) : 0;

	const installationHasDrivelinePositions = useMemo(
		() => hasDrivelinePositions(detailedSegment, purpose),
		[detailedSegment, purpose]
	);

	const Actions = useMemo(
		() =>
			isReadonly || isBlueprint
				? undefined
				: unitProductIndividualCardActions(
						driveline,
						drivelines,
						unitId
					),
		[]
	);

	const isUnitIPS40 = drivelines.some((d) =>
		d.productIndividualSubSystems.some((s) =>
			s.productIndividuals.some((p) => isIPS40(p.productName))
		)
	);

	return (
		<DrivelineItemView
			driveline={{ unitId, ...driveline }}
			headerActions={
				installationHasDrivelinePositions ? (
					<ToggleDrivelineLocation
						drivelineIndicator={driveline.position}
						drivelineTotalCount={drivelinesCount}
						isIPS40={isUnitIPS40}
						disabled={disabled}
					/>
				) : null
			}
			installationId={id.toString()}
			isReadonly={isReadonly}
			ProductIndividualCardActions={Actions}
			sx={sx}
			withLink={withLink}
		/>
	);
};
