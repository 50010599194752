import { Box, Grid2, Typography } from '@mui/material';
import { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import SpreadVolvoPentaLogo from 'assets/volvoIcons/Main/SpreadVolvoPentaLogo';
import StackedPentaLogo from 'assets/volvoIcons/Main/StackedPentaLogo';
import { getUserData } from 'library/redux/application';
import { useAppDispatch } from 'library/redux/hooks';

import { home_HomeUrl } from '../../routes/urls/RouteUrls';
import MobileMenuButton from './MobileMenuButton';
import Nav from './Nav';
import UserInfo from './UserInfo';
import { NavMenuProps } from './nav/models/NavMenuProps';

type HeaderProps = NavMenuProps;

const Header = ({
	isTabletMenuOpen,
	setIsTabletMenuOpen,
	isMobileMenuOpen,
	setIsMobileMenuOpen,
	selectedMenu,
	setSelectedMenu,
}: HeaderProps): JSX.Element => {
	const dispatch = useAppDispatch();

	// might be moved somewhere else, for now it was the best place, onAuthenticated is called too many times
	// todo - in the future, could be change to useQuery, as it automatically reconnect after internet failure
	useEffect(() => {
		dispatch(getUserData())
			.unwrap()
			.catch((ex) => {
				console.error(ex);
			});
	}, [dispatch]);

	return (
		<Box id="container__header" component="header">
			<Grid2 container className="header">
				<Grid2
					size={{ xs: 4 }}
					className={'header__head__item'}
					sx={{
						display: 'flex',
					}}>
					<Typography
						variant="h3"
						sx={{
							fontWeight: 'bold',
							fontSize: { xs: '0.75rem', md: '1rem' },
						}}>
						<Link to={home_HomeUrl} className="pc-link">
							Product Center
						</Link>
					</Typography>
				</Grid2>
				<Grid2
					size={{ xs: 4 }}
					className={'header__head__item'}
					sx={{
						display: 'flex',
						justifyContent: 'center',

						'& > svg': {
							height: 'unset',
							width: { xs: 115, sm: 280 },
						},

						'& > svg.stacked-penta-logo': {
							display: { sm: 'none' },
						},

						'& > svg.spread-penta-logo': {
							display: { xs: 'none', sm: 'block' },
						},
					}}>
					<StackedPentaLogo className="stacked-penta-logo" />
					<SpreadVolvoPentaLogo className="spread-penta-logo" />
				</Grid2>

				<Grid2
					sx={{
						display: {
							xs: 'none',
							md: 'flex',
						},
						justifyContent: 'flex-end',
					}}
					size={{ xs: 4 }}
					className={'header__head__item'}>
					<UserInfo />
				</Grid2>
				<Grid2
					size={{ xs: 4 }}
					sx={{
						display: {
							xs: 'block',
							sm: 'block',
							md: 'none',
							lg: 'none',
						},
					}}>
					<MobileMenuButton
						isMobileMenuOpen={isMobileMenuOpen}
						isTabletMenuOpen={isTabletMenuOpen}
						setIsMobileMenuOpen={setIsMobileMenuOpen}
						setIsTabletMenuOpen={setIsTabletMenuOpen}
						setSelectedMenu={setSelectedMenu}
						selectedMenu={selectedMenu}
					/>
				</Grid2>
				{isTabletMenuOpen && (
					<Grid2
						size={{ xs: 12, sm: 6, md: 12, lg: 12 }}
						sx={{
							display: {
								xs: 'none',
								sm: 'block',
								md: 'none',
								lg: 'none',
							},
						}}
						id="header__container__backdrop"></Grid2>
				)}
				<Grid2
					size={{ xs: 12, sm: 6, md: 12, lg: 12 }}
					id="header__container__nav">
					<Nav
						isMobileMenuOpen={isMobileMenuOpen}
						isTabletMenuOpen={isTabletMenuOpen}
						selectedMenu={selectedMenu}
						setIsMobileMenuOpen={setIsMobileMenuOpen}
						setIsTabletMenuOpen={setIsTabletMenuOpen}
						setSelectedMenu={setSelectedMenu}
					/>
				</Grid2>
			</Grid2>
		</Box>
	);
};

export default memo(Header);
