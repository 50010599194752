/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	ErrorResponse,
	NotFoundErrorResponse,
	PositionDto,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class V2<
	SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
	/**
	 * @description Includes: * Product individual comments * Product individual campaign info
	 *
	 * @tags Unit
	 * @name GetUnitStructureById
	 * @summary Returns unit information with whole unit structure.
	 * @request GET:/v2/unit/{id}/structure
	 * @secure
	 */
	getUnitStructureById = (id: number, params: RequestParams = {}) =>
		this.request<PositionDto, void | NotFoundErrorResponse | ErrorResponse>(
			{
				path: `/v2/unit/${id}/structure`,
				method: 'GET',
				secure: true,
				format: 'json',
				...params,
			}
		);
}
